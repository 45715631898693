import z from 'zod'

export const envSchema = z
  .object({
    VITE_ENVIRONMENT: z.enum(['local', 'development', 'production']).default('local'),
    VITE_API_BASE_URL: z.string(),
    VITE_FIREBASE_KEY: z.string(),
    VITE_FIREBASE_DOMAIN: z.string(),
    VITE_FIREBASE_PROJECT_ID: z.string(),
    VITE_FIREBASE_BUCKET: z.string(),
    VITE_FIREBASE_SENDER_ID: z.string(),
    VITE_FIREBASE_APP_ID: z.string(),
    VITE_AMPLITUDE_KEY: z.string(),
    VITE_MULTIMARKTS_APP_DOMAIN: z.string(),
    VITE_COOKIES_DOMAIN: z.string(),
    VITE_SPEEDRUN_CHUNK_SIZE: z.string(),
    VITE_SPEEDRUN_BEST_MATCH_MIN: z.string(),
    VITE_BEST_MATCH_MIN: z.string()
  })
  .transform((env) => ({
    environment: env.VITE_ENVIRONMENT,
    apiBaseUrl: env.VITE_API_BASE_URL,
    firebaseConfig: {
      apiKey: env.VITE_FIREBASE_KEY,
      authDomain: env.VITE_FIREBASE_DOMAIN,
      projectId: env.VITE_FIREBASE_PROJECT_ID,
      storageBucket: env.VITE_FIREBASE_BUCKET,
      messagingSenderId: env.VITE_FIREBASE_SENDER_ID,
      appId: env.VITE_FIREBASE_APP_ID
    },
    amplitudeBaseUrl: env.VITE_AMPLITUDE_KEY,
    multimarktsAppDomain: env.VITE_MULTIMARKTS_APP_DOMAIN,
    cookiesDomain: env.VITE_COOKIES_DOMAIN,
    featureFlags: {},
    speedRun: {
      chunkSize: parseInt(env.VITE_SPEEDRUN_CHUNK_SIZE),
      bestMatchMin: parseFloat(env.VITE_SPEEDRUN_BEST_MATCH_MIN)
    },
    bestMatchMin: parseFloat(env.VITE_BEST_MATCH_MIN)
  }))

export type ImportMetaEnv = z.infer<typeof envSchema>
