import { z } from 'zod'

export const userCredentialsSchema = z
  .object({
    jwt: z.string().optional().nullable().default(null)
  })
  .transform((credentials) => ({
    token: credentials.jwt
  }))

export type UserCredentials = z.infer<typeof userCredentialsSchema>
