import { z } from 'zod'
import { micrositeSchema } from '@/models/microsite.model'

export const profileSchema = z
  .object({
    createdAt: z.string(),
    updatedAt: z.string(),
    id: z.string(),
    username: z.string(),
    avatarUrl: z.string(),
    profileUrl: z.string().nullable().default(''),
    isSocialMediaLinked: z.boolean().default(false),
    microsite: micrositeSchema.optional(),
    feedbackToken: z.string().nullish(),
    email: z.string().optional()
  })
  .transform((profile) => ({
    id: profile.id,
    username: profile.username,
    avatar: profile.avatarUrl,
    isSocialMediaLinked: profile.isSocialMediaLinked,
    microsite: profile.microsite,
    feedbackToken: profile.feedbackToken,
    email: profile.email || ''
  }))

export const profileSocialLinkSchema = z
  .object({
    linkUrl: z.string()
  })
  .transform((links) => ({
    socialLink: links.linkUrl
  }))

export type SocialLinks = z.infer<typeof profileSocialLinkSchema>
export type UserProfile = z.infer<typeof profileSchema>
