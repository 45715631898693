<template>
  <section class="login">
    <header class="login__header">
      <img class="login__header__logo" src="@/assets/images/svg/logo-mmkts-black.svg" />
    </header>
    <div class="login__content">
      <LottieAnimation :animation-data="signInAnimation" />
      <div class="login__heading">
        <p class="login__heading__title heading-xs">
          {{ $t('auth_login_title') }}
        </p>
      </div>
    </div>
    <FooterActions class="login__footer">
      <GoogleLoginCTA
        class="login__footer__action"
        @on-success="onGoogleLogin"
        @on-click="handleGoogleClick"
      />
      <p class="login__footer__hint text-sm">{{ $t('auth_login_register_hint') }}</p>
      <router-link class="login__footer__link btn btn--ghost" to="/register">
        {{ $t('auth_login_register_link') }}</router-link
      >
    </FooterActions>
  </section>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user'
import { useMetrics } from '@/composables/useMetrics'
import { EVENT_NAMES, USER_TYPES } from '@/types/events'
import * as signInAnimation from '@/assets/lottie/access.json'
import { useMicrositeStore } from '@/stores/microsite'

definePage({
  meta: {
    public: true
  },
  name: 'login_page'
})

const authStore = useAuthStore()
const uiStore = useUiStore()
const userStore = useUserStore()
const micrositeStore = useMicrositeStore()
const router = useRouter()
const { sendEvent, updateUserId } = useMetrics()

const userProfile = computed(() => userStore.getProfile())

const handleGoogleClick = () => {
  sendEvent(EVENT_NAMES.CLICK_ACCESS, {
    auth_method: 'login',
    provider: 'google'
  })
}

const onGoogleLogin = async (token: string) => {
  uiStore.showLoader({
    animation: 'https://lottie.host/9ee855f4-c8d5-4833-8d65-e7338d764d28/6b5fu4UFCU.json',
    title: 'loader_default_title'
  })
  await authStore.fetchUserToken(token)
  await userStore.fetchUserProfile()
  await micrositeStore.fetchMicrosite()
  updateUserId(userProfile.value.id)
  sendEvent(EVENT_NAMES.USER_AUTH, {
    auth_action: micrositeStore.getMicrosite().id ? 'login' : 'register',
    user_type: USER_TYPES.INFLUENCER
  })
  uiStore.hideLoader()

  router.push(micrositeStore.microsite.isOnboardingCompleted ? '/' : '/onboarding')
}
</script>

<style scoped lang="scss">
.login {
  background-color: $brand-yellow-500;
  color: $neutral-600;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &__header {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: $spacing-size-4;
    position: sticky;
    top: 0;
    z-index: 1;

    &__logo {
      height: $spacing-size-5;
      width: 6.25 * $base-unit;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: $spacing-size-6;
    height: 100%;
    justify-content: center;
    margin: auto;
    max-width: $container-width;

    :deep(.lottie-animation) {
      height: 16.25 * $base-unit;
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: $spacing-size-4;
    margin: 0 auto;
    max-width: 21 * $base-unit;
    text-align: center;

    &__title {
      color: $black-100;
    }

    &__subtitle {
      color: $primary-900;
    }
  }

  &:deep(.footer-actions) {
    background-color: $brand-yellow-500;
    padding-bottom: $spacing-size-6;
  }

  &__footer {
    align-items: center;
    color: $neutral-900;
    display: flex;
    flex-direction: column;
    gap: 0;
    text-align: center;

    &__action {
      margin-bottom: $spacing-size-7;
    }

    &__link {
      min-height: 40px;
      padding: $spacing-size-2 $spacing-size-1;

      &:hover {
        background-color: $brand-yellow-500;
      }
    }
  }

  @media (height >= 700px) {
    &__content {
      :deep(.lottie-animation) {
        height: 350px;
      }
    }
  }

  @media (height >= 850px) {
    &__content {
      :deep(.lottie-animation) {
        height: 100%;
      }
    }
  }
}
</style>
