import Cookie from 'js-cookie'
import { ENV } from '@/constants/env'

interface CookieStorageOpts {
  secure?: boolean
  expires?: number //cookie expiration in days
  domain?: string
  sameSite?: 'None' | 'strict' | 'Strict' | 'lax' | 'Lax' | 'none'
}

const isLocalEnvironment = ENV.environment === 'local'

export function createCookieStorage({
  secure,
  expires = 365,
  sameSite = 'lax'
}: CookieStorageOpts) {
  const getItem = (key: string): string | null => {
    return Cookie.get(key) ?? null
  }
  const setItem = (key: string, value: string) => {
    Cookie.set(key, value, {
      secure: secure ?? !isLocalEnvironment,
      expires,
      sameSite,
      domain: ENV.cookiesDomain
    })
  }

  return {
    getItem,
    setItem
  }
}
