import { HUBSPOT_CONFIG } from '@/constants/hubspot-config'

export function setupHubspotChat() {
  window.hsConversationsSettings = {
    loadImmediately: false,
    enableWidgetCookieBanner: true,
    disableAttachment: true
  }

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = HUBSPOT_CONFIG.src
  script.id = HUBSPOT_CONFIG.id
  script.async = true
  script.defer = true

  document.body.appendChild(script)
}
