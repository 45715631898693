<template>
  <div class="settings">
    <ul class="menu text-xl">
      <li class="menu__item" @click="onLogout">
        <i class="mkt-sign-out"></i>
        {{ t('general_logout') }}
      </li>
    </ul>
    <div class="settings__bottom">
      <button class="settings__bottom__delete-button btn btn--ghost" @click="deleteModal = true">
        <i class="mkt-trash"></i>
        {{ t('settings_delete_account') }}
      </button>
    </div>
  </div>
  <Dialog v-model="deleteModal">
    <template v-slot:content>
      <div class="delete-card__text">
        <h3 class="heading-xs">
          {{ t('settings_delete_account') }}
        </h3>
        <p class="text-md">
          {{ t('settings_delete_account_advice') }}
          <br />
          {{ t('general_cant_be_undone') }}
        </p>
      </div>
    </template>
    <template v-slot:actions>
      <button type="button" class="btn btn--error" @click="onDelete">
        {{ t('settings_delete_account_confirm') }}
      </button>
      <button type="button" @click="deleteModal = false" class="btn">
        {{ t('settings_delete_account_cancel') }}
      </button>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useMetrics } from '@/composables/useMetrics'
import { EVENT_NAMES } from '@/types/events'
import { useUserStore } from '@/stores/user'
import { useMicrositeStore } from '@/stores/microsite'

definePage({
  meta: {
    layout: 'app',
    pageTitleKey: 'general_page_title_settings'
  },
  name: 'admin_settings'
})

const { t } = useI18n()
const { sendEvent } = useMetrics()
const userStore = useUserStore()
const micrositeStore = useMicrositeStore()
const uiStore = useUiStore()

const deleteModal = ref(false)

const onLogout = () => {
  useAuthStore().logout()
}

const onDelete = async () => {
  deleteModal.value = false
  uiStore.showLoader({
    animation: 'https://lottie.host/9ee855f4-c8d5-4833-8d65-e7338d764d28/6b5fu4UFCU.json'
  })

  await userStore.deleteUser()

  sendEvent(EVENT_NAMES.DELETE_ACCOUNT, {
    microsite_id: micrositeStore.getMicrosite().id,
    user_id: userStore.getProfile().id
  })

  window.location.href = `https://multimarkts.com`
}

watch(
  () => deleteModal.value,
  (open) => {
    const eventName = open ? EVENT_NAMES.MODAL_OPEN : EVENT_NAMES.MODAL_CLOSE
    sendEvent(eventName, {
      modal_name: 'delete_account'
    })
  }
)
</script>

<style scoped lang="scss">
.settings {
  color: $neutral-700;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  &__bottom {
    padding: $spacing-size-6 $spacing-size-7;

    &__delete-button {
      color: $red-500;
    }
  }
}

.delete-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  &__text {
    align-items: center;
    color: $neutral-600;
    display: flex;
    flex-direction: column;
    gap: $spacing-size-2;
    text-align: center;
    white-space: pre-line;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: $spacing-size-2;
  }
}
</style>
