import { z } from 'zod'
import { PostType } from '@/types/general'
import { productSchema } from '@/models/products.model'

export const postSchema = z
  .object({
    id: z.string(),
    mediaUrl: z.string(),
    productIds: z.string().array(),
    status: z.string().optional().default('Pending'),
    publishedAt: z.string(),
    updatedAt: z.string().optional(),
    caption: z.string().nullable().optional().default(''),
    type: z.string().optional().default(PostType.Image),
    thumbnailUrl: z.string().nullable(),
    shopTheStyleProducts: productSchema.array()
  })
  .transform((post) => {
    return {
      id: post.id,
      mediaUrl: post.mediaUrl,
      status: post.status,
      title: post.caption,
      hasProductsUpdated: false,
      publishedAt: new Date(post.publishedAt).getTime(),
      updatedAt: new Date().getTime() - 5 * 24 * 60 * 60 * 1000, // meanwhile it came from API
      type: post.type,
      thumbnailUrl: post.thumbnailUrl,
      products: post.shopTheStyleProducts ?? []
    }
  })

export type PostApi = z.infer<typeof postSchema>
