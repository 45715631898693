import { defineStore } from 'pinia'
import type { Microsite } from '@/models/microsite.model'
import type { Product } from '@/models/products.model'
import { MicrositeService } from '@/api/microsite.service'
import { StoreNamespaces } from '@/types/store'
import { usePostsStore } from '@/stores/posts'
import { useUiStore } from '@/stores/ui'

export interface ProductUi extends Product {
  isSelected: boolean
}

export const useMicrositeStore = defineStore(
  StoreNamespaces.microsite,
  () => {
    const micrositeService = new MicrositeService()
    const uiStore = useUiStore()
    const postsStore = usePostsStore()
    const productsStore = useProductsStore()

    const postIdsWithProducts = ref<Array<string>>([])
    const microsite = ref<Microsite>({} as Microsite)
    const hasOldPostsToRecover = ref<boolean>(true)
    const isZexelClicked = ref<boolean>(false)

    const fetchMicrosite = async (isForced: boolean = false) => {
      try {
        if (isForced || !Object.keys(microsite.value).length) {
          const microsite = await micrositeService.getUserMicrosite()
          setMicrosite(microsite)
        }
      } catch (e: any) {
        console.log('Error fetching microsite.', e)
      }
    }

    const updateMicrosite = async (redirectUrl: string, instagramCode: string) => {
      const microsite = await micrositeService.patchMicrosite(redirectUrl, instagramCode)
      setMicrosite(microsite)
    }

    const fetchMicrositePosts = async () => {
      if (microsite.value.id) {
        try {
          const posts = await micrositeService.fetchMicrositePosts(microsite.value.id)
          posts.forEach((post) => {
            const { products, ..._post } = post
            const productIds: Array<string> = []
            products.forEach((product) => {
              productsStore.setProduct(product)
              productIds.push(product.id)
            })
            postsStore.setUserPost({ ..._post, productIds })
          })
        } catch (e) {
          console.log('Error fetching posts. MicrositeId: ', microsite.value.id, e)
        }
      }
    }

    const updateNewMicrositePosts = async () => {
      const { retryAfter } = await micrositeService.requestUpdateNewMicrositePostsManually(
        microsite.value.id
      )
      await fetchMicrositePosts()
      await postsStore.fetchProductsFromDraftPosts()
      return retryAfter
    }

    const updatePreviousMicrositePosts = async () => {
      const { retryAfter, syncedPosts } =
        await micrositeService.requestUpdatePreviousMicrositePostsManually(microsite.value.id)
      if (retryAfter) {
        uiStore.showRateLimitPostUpdateSnackbar(retryAfter)
      }

      if (syncedPosts) {
        await fetchMicrositePosts()
        await postsStore.fetchProductsFromDraftPosts()
      } else {
        hasOldPostsToRecover.value = false
      }
      return retryAfter
    }

    const linkSocialAccounts = async (redirect: string) => {
      try {
        const { socialLink } = await micrositeService.getMicrositeSocialLinkPage(redirect)
        return socialLink
      } catch (e: any) {
        console.log('Error linking social accounts.', e)
      }
    }

    const completeOnboarding = async () => {
      try {
        await micrositeService.completeOnboarding()
      } catch (e: any) {
        console.log('Error finishing microsite onboarding', e)
      }
    }

    const setMicrosite = (_microsite: Microsite) => {
      microsite.value = _microsite
    }

    const getMicrosite = () => {
      return microsite.value
    }

    const getIsSocialMediaLinked = () => microsite.value.isSocialMediaLinked

    const unlinkUserMediaAccount = async (socialMedia: string) => {
      await micrositeService.socialMediaUnlink(socialMedia)
      await fetchMicrosite(true)
    }

    const setIsZexelClicked = (_isZexelClicked: boolean) => {
      isZexelClicked.value = _isZexelClicked
    }

    const getIsZexelClicked = () => isZexelClicked.value

    const $reset = () => {
      microsite.value = {} as Microsite
    }

    return {
      isZexelClicked,
      postIdsWithProducts,
      microsite,
      hasOldPostsToRecover,
      fetchMicrositePosts,
      updateNewMicrositePosts,
      updatePreviousMicrositePosts,
      fetchMicrosite,
      completeOnboarding,
      updateMicrosite,
      getMicrosite,
      getIsSocialMediaLinked,
      linkSocialAccounts,
      $reset,
      unlinkUserMediaAccount,
      setIsZexelClicked,
      getIsZexelClicked
    }
  },
  {
    persist: {
      paths: ['isZexelClicked']
    }
  }
)
