import Http from '@/lib/http'
import * as Sentry from '@sentry/vue'
import { ENV } from '@/constants/env'
import { productSchema, type Product } from '@/models/products.model'
import type { PatchPayloadInfluencerProduct, BoundsInPostApi } from '@/types/api'
import { type PostApi, postSchema } from '@/models/posts.model'

export class PostsService extends Http {
  constructor() {
    super(ENV.apiBaseUrl)
  }

  async getProductsInPost(src: string): Promise<Array<Product>> {
    try {
      const { items: bounds }: { items: Array<BoundsInPostApi> } = await this.get(
        `posts/${src}/products`
      )
      return bounds.reduce((acc, bound) => {
        const products = bound.products.map((product) => productSchema.parse(product))
        acc = acc.concat(products)
        return acc
      }, [] as Array<Product>)
    } catch (e: any) {
      Sentry.captureException(e)
      return []
    }
  }

  async getPostData(src: string): Promise<PostApi> {
    try {
      const post: any = await this.post(`/posts/${src}/auto-publish`, {})
      return postSchema.parse(post)
    } catch (e: any) {
      Sentry.captureException(e)
      return {} as PostApi
    }
  }

  async unpublishPost(postId: string): Promise<any> {
    try {
      const post: any = await this.post(`/posts/${postId}/unpublish`, {})
      return post
    } catch (e: any) {
      Sentry.captureException(e)
      return {} as PostApi
    }
  }

  async updateProductsInPost(payload: PatchPayloadInfluencerProduct) {
    return await this.patch(`/posts/${payload.postId}/products`, {
      productIds: payload.products,
      isAutoCommentEnabled: false
    })
  }
}
