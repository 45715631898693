<template>
  <div
    :class="[
      'error',
      {
        [`error--${error.type}`]: Boolean(error.type)
      }
    ]"
  >
    <div class="error__content">
      <div class="error__content__icon">
        <i class="mkt-alert"></i>
      </div>
      <div class="error__content__text">
        <h2 class="error__content__text__title heading-xs">{{ t(error.title) }}</h2>
        <p class="error__content__text__description text-md">
          {{ t(error.message) }}
        </p>
      </div>
    </div>
    <div v-if="!error.isCritical" class="error__action">
      <a v-if="urlIsExternal" :href="error.buttonLink" class="btn btn--primary" target="_self">
        {{ t(error.buttonText) }}
      </a>
      <RouterLink v-else :to="error.buttonLink" class="btn btn--primary">
        {{ t(error.buttonText) }}
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '@/stores/ui'

const { t } = useI18n()
const uiStore = useUiStore()
const authStore = useAuthStore()
const error = computed(() => useUiStore().getError)

definePage({
  name: 'ob_error',
  meta: {
    public: true,
    layout: 'error'
  }
})

if (error.value.isCritical) {
  uiStore.hideLoader()
  setTimeout(() => {
    authStore.logout()
  }, error.value.delayTime)
}

const urlIsExternal = computed(() => {
  const regex = [/^https:\/\//i, /^mailto:/i]
  return regex.some((urlRegex) => urlRegex.test(error.value.buttonLink))
})
</script>

<style scoped lang="scss">
.error {
  $error: &;

  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $spacing-size-7 $spacing-size-6;

  &--warning {
    #{$error}__content {
      &__icon {
        background-color: $warning-50;
        color: $warning-500;
      }
    }
  }

  &--error {
    #{$error}__content {
      &__icon {
        background-color: $error-50;
        color: $error-500;
      }
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: $spacing-size-4;
    justify-content: center;
    text-align: center;

    &__icon {
      align-items: center;
      align-self: center;
      background-color: $neutral-50;
      border-radius: 100%;
      display: flex;
      height: $spacing-size-9;
      justify-content: center;
      width: $spacing-size-9;
    }

    &__text {
      color: $neutral-900;
      display: flex;
      flex-direction: column;
      gap: $spacing-size-1;

      &__title {
        font-weight: $font-regular;
      }
    }
  }
}
</style>
