import Http from '@/lib/http'
import { ENV } from '@/constants/env'
import {
  type Metric,
  metricSchema,
  monetizationMetricSchema,
  monetizationTransactionsListSchema
} from '@/models/metric.model'
import type { MonetizationTransactionsPayload } from '@/types/api'

export class MetricsService extends Http {
  constructor() {
    super(ENV.apiBaseUrl)
  }

  async getMetricsByMicrositeId(micrositeId: string): Promise<Metric[]> {
    const metrics = await this.get(`/microsites/${micrositeId}/metrics/`)
    return metrics.map((m: unknown) => metricSchema.parse(m))
  }

  async getMonetizationMetrics(range: string): Promise<any> {
    const metrics = await this.get(`/microsites/me/monetization-metrics?intervalDays=${range}`)
    return monetizationMetricSchema.parse(metrics)
  }

  async getMonetizationTransactions(payload: MonetizationTransactionsPayload): Promise<any> {
    const metrics = await this.get(
      `/microsites/me/sale-transactions?intervalDays=${payload.range}&offset=${payload.offset}&limit=${payload.limit}`
    )
    return monetizationTransactionsListSchema.parse(metrics)
  }
}
