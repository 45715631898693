import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { ENV } from '@/constants/env'
import type { EVENT_NAMES } from '@/types/events'
import type { EventPayloads } from '@/types/metric-events'
import { createCookieStorage } from '@/lib/storage'
import i18n from '@/lib/i18n'
import { useMicrositeStore } from '@/stores/microsite'

let preLoadEvents: Array<{
  eventType: EVENT_NAMES
  eventProperties: EventPayloads[EVENT_NAMES]
}> = []
let isLoaded = false

export function useMetrics() {
  const userStore = useUserStore()
  const micrositeStore = useMicrositeStore()
  const cookieStorage = createCookieStorage({})

  const userId = computed(() =>
    userStore.getProfile().id.length
      ? userStore.getProfile().id
      : cookieStorage.getItem('userId') || ''
  )

  const initialize = async () => {
    const sessionReplayTracking = sessionReplayPlugin({
      debugMode: true,
      sampleRate: 1
    })
    await amplitude.add(sessionReplayTracking).promise
    await amplitude.init(ENV.amplitudeBaseUrl, userId.value).promise
    const locale = toValue(i18n.global.locale)

    const identify = new amplitude.Identify()
    identify.set('app_language', locale)
    identify.set('screen_width', window.screen.width)
    identify.set('screen_height', window.screen.height)
    identify.set('viewport_width', window.innerWidth)
    identify.set('viewport_height', window.innerHeight)

    const stopMicrositeWatcher = watch(
      () => micrositeStore.getMicrosite(),
      (microsite) => {
        if (microsite.id) {
          stopMicrositeWatcher()
          identify.set('referer_microsite_id', microsite.id)
          amplitude.identify(identify)
        }
      }
    )
    isLoaded = true

    preLoadEvents.forEach((_event) => {
      sendEvent(_event.eventType, _event.eventProperties)
    })
    preLoadEvents = []
  }

  const updateUserId = (newUserId: string) => {
    cookieStorage.setItem('userId', newUserId)
    amplitude.setUserId(newUserId)
  }

  const sendEvent = <T extends EVENT_NAMES>(eventType: T, eventProperties: EventPayloads[T]) => {
    if (isLoaded) {
      amplitude.track(eventType, eventProperties as Record<string, any>)
    } else {
      preLoadEvents.push({ eventType, eventProperties })
    }
  }

  return {
    initialize,
    updateUserId,
    sendEvent
  }
}
