import type { RouteNamedMap } from 'vue-router/auto-routes'
import type { MaybeRef } from '@vueuse/core'

export enum PostStatus {
  Published = 'published',
  Draft = 'draft',
  Skipped = 'skipped'
}

export enum PostType {
  Video = 'video',
  Image = 'image'
}

export interface AppRoute {
  name: string
  to: keyof RouteNamedMap | string
  icon?: string
  children?: AppRoute[]
  disabled?: boolean
  hint?: {
    message?: string | number
    icon?: string
    type: string
  }
  hasAlert?: boolean
  target?: string
  onClick?: () => void
}

export interface Metric {
  value: number | string
  legend?: string
  icon: string
  columns: number
  total?: number | string
  isHighlight?: boolean
}

export interface MetricOptions {
  items: Array<MetricOptionsItem>
  model: MaybeRef<any>
  prevIcon?: string
  postsIcon?: string
}

export interface MetricsSections {
  title: string
  id: string
  metrics: Array<Metric>
  lastUpdate: string
  isRequesting: boolean
  emptyData: {
    status: boolean
    title: string
    subtitle: string
    icon: string
  }
  alert?: {
    show: boolean
    icon: string
    title: string
    subtitle?: string
    link: string
    callback: () => void
  }
  options?: MetricOptions
  navigation?: {
    to: string
    icon: string
  }
}

export interface MetricOptionsItem {
  label: string
  value: string | number
  name: string
}
