import { createApp } from 'vue'
import { createHead } from '@unhead/vue'
import i18n from '@/lib/i18n'
import pinia from '@/lib/pinia'
import * as Sentry from '@sentry/vue'
import '@/assets/scss/main.scss'
import VueObserveVisibility from 'vue3-observe-visibility'

import App from './App.vue'
import Router from '@/lib/router'
import { useMetrics } from '@/composables/useMetrics'
import { ENV } from '@/constants/env'
import { setupHubspotChat } from '@/lib/hubspot'

const head = createHead()
const app = createApp(App)

const isProductionEnvironment = ENV.environment === 'production'
const isLocalEnvironment = ENV.environment === 'local'

setupHubspotChat()

if (!isLocalEnvironment) {
  Sentry.init({
    app,
    environment: ENV.environment,
    dsn: 'https://fdea1f5d5de0be78ca08fabf64c88581@o401110.ingest.us.sentry.io/4507333993037824',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^http:\/\/localhost/,
      /^https:\/\/dev-api\.multimarkts\.club\/v1/,
      /^https:\/\/api\.multimarkts\.com\/v1/
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: isProductionEnvironment ? 1.0 : 0
  })
}

app.use(Router)
app.use(pinia)
app.use(i18n)
app.use(head)
app.use(VueObserveVisibility)

app.mount('#app')

const amplitude = useMetrics()
amplitude.initialize()
