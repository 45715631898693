import Http from '@/lib/http'
import { ENV } from '@/constants/env'
import { profileSchema, type UserProfile } from '@/models/profile.model'

export class UserService extends Http {
  constructor() {
    super(ENV.apiBaseUrl)
  }

  async getUserProfile(): Promise<UserProfile> {
    const profile = await this.get('/users/me')
    return profileSchema.parse(profile)
  }

  createFeedbackToken(): Promise<{ feedbackToken: string }> {
    return this.post(`/users/me/feedback-credentials`, {})
  }

  async deletetUserProfile(): Promise<void> {
    await this.delete('/users/me')
    return
  }
}
