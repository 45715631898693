<template>
  <section v-show="_isInApp" class="social-link-reception">
    <h1 class="social-link-reception__title heading-xs">{{ $t('social_link_reception_title') }}</h1>
    <p class="social-link-reception__subtitle text-md">
      {{ $t('social_link_reception_subtitle') }}
    </p>
    <a :href="destiny" class="social-link-reception__link btn btn--primary">{{
      $t('social_link_reception_link')
    }}</a>
  </section>
</template>

<script setup lang="ts">
import { getOnboardingRoute, getBrowserRelativePath, isInApp } from '@/tools/socialLinkRedirect'

definePage({
  meta: {
    public: true
  },
  name: 'ob_redirect_page'
})
const route = useRoute()

const { browser } = route.params as Record<string, string>
const { code } = route.query
const _isInApp = isInApp()

const destiny = route.query.code
  ? getOnboardingRoute(code as string, browser)
  : getBrowserRelativePath(location.origin)

window.location.href = destiny
</script>

<style lang="scss" scoped>
.social-link-reception {
  padding: $spacing-size-4 $spacing-size-6;
  text-align: center;

  &__title {
    padding-bottom: $spacing-size-4;
  }

  &__subtitle {
    padding-bottom: $spacing-size-10;
  }

  &__link {
    display: inline-flex;
    width: initial;
  }
}
</style>
