import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import i18n from '@/lib/i18n'

import { ENV } from '@/constants/env'

export enum FIREBASE_AUTH_PROVIDERS {
  GOOGLE = 'google',
  FACEBOOK = 'facebook'
}

export class FirebaseAuth {
  private readonly config
  locale
  auth

  constructor() {
    this.config = ENV.firebaseConfig
    this.locale = toValue(i18n.global.locale)

    initializeApp(this.config)

    this.auth = getAuth()
  }

  private loginWithGoogle() {
    this.auth.languageCode = this.locale
    return new GoogleAuthProvider()
  }

  async login(authProvider: FIREBASE_AUTH_PROVIDERS) {
    if (authProvider === FIREBASE_AUTH_PROVIDERS.FACEBOOK) {
      throw new Error('Facebook auth error: not implemented')
    } else {
      const provider = this.loginWithGoogle()
      const { user } = await signInWithPopup(this.auth, provider)

      // @ts-ignore
      return user.accessToken
    }
  }

  async logout() {
    try {
      await signOut(this.auth)
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return true
    }
  }
}
