<template>
  <div class="social-accounts">
    <PageTitle />
    <p class="social-accounts__hint text-sm">{{ $t('social_accounts_hint') }}</p>
    <ul class="menu text-md">
      <li class="menu__item">
        <i class="mkt-instagram" />
        <span class="menu__item__text">
          {{ getSocialLinkedText }}
        </span>
        <div @click="onInstagramSwitchClick">
          <Checkbox style="pointer-events: none" v-model="instagramLinked" name="checkInstagram" />
        </div>
      </li>
    </ul>
  </div>
  <Dialog v-model="linkDialog" :close-on-click-out="linkStep !== LinkAccountStep.linkInProgress">
    <template v-slot:content>
      <div
        :class="[
          'link-account-dialog',
          {
            'link-account-dialog--error': linkStep === LinkAccountStep.linkFailure,
            'link-account-dialog--success': linkStep === LinkAccountStep.linkSuccess
          }
        ]"
      >
        <div class="link-account-dialog__content">
          <Loader v-if="linkStep === LinkAccountStep.linkInProgress" />
          <div class="link-account-dialog__icon">
            <i :class="linkDialogContent.icon" />
          </div>
          <h3 class="link-account-dialog__title heading-xs">
            {{ linkDialogContent.title }}
          </h3>
          <p class="link-account-dialog__description text-md">
            {{ linkDialogContent.description }}
          </p>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <button
        v-if="linkDialogContent.actionHandler"
        @click="linkDialogContent.actionHandler"
        :class="[
          'link-account-dialog__actions__confirm btn',
          {
            'btn--error': linkStep === LinkAccountStep.linkFailure,
            'btn--primary': linkStep === LinkAccountStep.linkSuccess
          }
        ]"
      >
        {{ linkDialogContent.actionButtonText ?? t('general_end') }}
      </button>
      <button
        class="link-account-dialog__actions__cancel btn btn--ghost"
        @click="linkDialog = false"
      >
        {{ t('general_cancel') }}
      </button>
    </template>
  </Dialog>

  <Dialog
    v-model="unlinkDialog"
    :close-on-click-out="unlinkStep !== UnlinkAccountStep.unlinkInProgress"
  >
    <template v-slot:content>
      <div
        :class="[
          'link-account-dialog',
          {
            'link-account-dialog--error': unlinkStep !== UnlinkAccountStep.unlinkSuccess,
            'link-account-dialog--success': unlinkStep === UnlinkAccountStep.unlinkSuccess,
            'link-account-dialog--loading': unlinkStep === UnlinkAccountStep.unlinkInProgress
          }
        ]"
      >
        <div class="link-account-dialog__content">
          <div class="link-account-dialog__icon">
            <i :class="unlinkDialogContent.icon" />
          </div>
          <h3 class="link-account-dialog__title heading-xs">
            {{ unlinkDialogContent.title }}
          </h3>
          <p class="link-account-dialog__description text-md">
            {{ unlinkDialogContent.description }}
          </p>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <button
        v-if="unlinkDialogContent.actionHandler"
        @click="unlinkDialogContent.actionHandler"
        :class="[
          'link-account-dialog__actions__confirm btn',
          {
            'btn--error': unlinkStep !== UnlinkAccountStep.unlinkSuccess,
            'btn--primary': unlinkStep === UnlinkAccountStep.unlinkSuccess
          }
        ]"
      >
        <loader
          class="link-account-dialog__actions__loader"
          v-if="unlinkStep === UnlinkAccountStep.unlinkInProgress"
          main-color="#b42318"
          bar-color="white"
        ></loader>
        <span v-else>{{ unlinkDialogContent.actionButtonText ?? t('general_end') }}</span>
      </button>
      <button
        :class="[
          'link-account-dialog__actions__cancel btn btn--ghost',
          { 'btn--disabled': unlinkStep === UnlinkAccountStep.unlinkInProgress }
        ]"
        @click="unlinkDialog = false"
      >
        {{ t('general_cancel') }}
      </button>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { EVENT_NAMES } from '@/types/events'
import { useMicrositeStore } from '@/stores/microsite'

enum LinkAccountStep {
  linkInProgress = 'linkInProgress',
  linkSuccess = 'linkSuccess',
  linkFailure = 'linkFailure'
}
enum UnlinkAccountStep {
  unlinkStart = 'unlinkStart',
  unlinkInProgress = 'unlinkInProgress',
  unlinkSuccess = 'unlinkSuccess',
  unlinkFailure = 'unlinkFailure'
}

definePage({
  meta: {
    layout: 'app',
    pageTitleKey: 'general_page_title_social_accounts'
  },
  name: 'social_accounts'
})

const route = useRoute()
const micrositeStore = useMicrositeStore()
const { t } = useI18n()
const { sendEvent } = useMetrics()

const linkDialogContent = computed<{
  title: string
  description: string
  icon?: string
  actionButtonText?: string
  actionHandler?: () => void
}>(() => {
  const texts = {
    [LinkAccountStep.linkInProgress]: {
      title: t('social_accounts_link_in_progress_title'),
      description: t('social_accounts_link_in_progress_description')
    },
    [LinkAccountStep.linkSuccess]: {
      title: t('social_accounts_link_success_title', { platform: 'Instagram' }),
      description: t('social_accounts_link_success_description', { platform: 'Instagram' }),
      icon: 'mkt-check',
      actionHandler: () => {
        linkDialog.value = false
      }
    },
    [LinkAccountStep.linkFailure]: {
      title: t('social_accounts_link_fail_title'),
      description: t('social_accounts_link_fail_description'),
      icon: 'mkt-alert',
      actionButtonText: t('general_try_again'),
      actionHandler: () => {
        sendEvent(EVENT_NAMES.ERROR_TRY_AGAIN, {
          modal_name: 'error_linking_account'
        })
      }
    }
  }

  return texts[linkStep.value]
})

const unlinkDialogContent = computed<{
  title: string
  description: string
  icon?: string
  actionButtonText?: string
  actionHandler?: () => void
}>(() => {
  const texts = {
    [UnlinkAccountStep.unlinkStart]: {
      title: t('social_accounts_unlink_title', { platform: 'Instagram' }),
      description: t('social_accounts_unlink_description', { platform: 'Instagram' }),
      icon: 'mkt-alert',
      actionButtonText: t('social_accounts_unlink_action_text'),
      actionHandler: () => {
        sendEvent(EVENT_NAMES.ACCOUNT_UNLINK, {})
        handleSocialUnlink()
      }
    },
    [UnlinkAccountStep.unlinkInProgress]: {
      title: t('social_accounts_unlink_title', { platform: 'Instagram' }),
      description: t('social_accounts_unlink_description', { platform: 'Instagram' }),
      icon: 'mkt-alert',
      actionHandler: () => {}
    },
    [UnlinkAccountStep.unlinkSuccess]: {
      title: t('social_accounts_unlink_success_title'),
      description: t('social_accounts_unlink_success_description', { platform: 'Instagram' }),
      icon: 'mkt-check',
      actionHandler: () => {
        unlinkDialog.value = false
      }
    },
    [UnlinkAccountStep.unlinkFailure]: {
      title: t('social_accounts_link_fail_title'),
      description: t('social_accounts_unlink_fail_description'),
      icon: 'mkt-alert',
      actionButtonText: t('general_try_again'),
      actionHandler: () => {
        sendEvent(EVENT_NAMES.ERROR_TRY_AGAIN, {
          modal_name: 'error_unlinking_account'
        })
      }
    }
  }

  return texts[unlinkStep.value]
})

const tokenCode = computed(() => route.query.code ?? '')
const socialMediaSlugs = computed(
  () =>
    new Map(
      (micrositeStore.getMicrosite().socialMediaHandlers || []).map((social) => [
        social.platform,
        social.handler
      ])
    )
)

const getSocialLinkedText = computed(() => {
  return instagramLinked.value
    ? `${t('social_accounts_connected_as')} @${socialMediaSlugs.value.get('instagram')}`
    : t('social_accounts_link_text_default')
})

const linkDialog = ref(false)
const unlinkDialog = ref(false)
// const socialLink = ref('')
const linkStep = ref<LinkAccountStep>(LinkAccountStep.linkInProgress)
const unlinkStep = ref<UnlinkAccountStep>(UnlinkAccountStep.unlinkStart)
const instagramLinked = ref(socialMediaSlugs.value.has('instagram'))

const onInstagramSwitchClick = async () => {
  sendEvent(EVENT_NAMES.LINKED_ACCOUNTS_SWITCH, {
    switch: instagramLinked.value ? 'turn_off' : 'turn_on'
  })

  if (instagramLinked.value) {
    unlinkStep.value = UnlinkAccountStep.unlinkStart
    unlinkDialog.value = true
  } else {
    linkDialog.value = true
    window.location.href =
      (await micrositeStore.linkSocialAccounts(`${location.origin}/social-accounts`)) || ''
  }
}

const handleSocialLink = async () => {
  linkStep.value = LinkAccountStep.linkInProgress
  linkDialog.value = true
  try {
    await micrositeStore.updateMicrosite(
      `${location.origin}/social-accounts`,
      tokenCode.value as string
    )
    linkStep.value = LinkAccountStep.linkSuccess
    instagramLinked.value = true
    sendEvent(EVENT_NAMES.MODAL_OPEN, {
      modal_name: 'account_linked'
    })
  } catch (e) {
    linkStep.value = LinkAccountStep.linkFailure
    sendEvent(EVENT_NAMES.MODAL_OPEN, {
      modal_name: 'error_linking_account'
    })
  }
}

const handleSocialUnlink = async () => {
  unlinkStep.value = UnlinkAccountStep.unlinkInProgress
  unlinkDialog.value = true

  try {
    await micrositeStore.unlinkUserMediaAccount('instagram')
    unlinkStep.value = UnlinkAccountStep.unlinkSuccess
    instagramLinked.value = false
    sendEvent(EVENT_NAMES.MODAL_OPEN, {
      modal_name: 'account_unlinked'
    })
  } catch (e) {
    unlinkStep.value = UnlinkAccountStep.unlinkFailure
    sendEvent(EVENT_NAMES.MODAL_OPEN, {
      modal_name: 'error_unlinking_account'
    })
  }
}

watch(
  () => linkStep.value,
  (value) => {
    if (
      !value &&
      (linkStep.value === LinkAccountStep.linkFailure ||
        linkStep.value === LinkAccountStep.linkSuccess)
    ) {
      sendEvent(EVENT_NAMES.MODAL_CLOSE, {
        modal_name:
          linkStep.value === LinkAccountStep.linkFailure
            ? 'error_linking_account'
            : 'account_linked'
      })
    }
  }
)

watch(
  () => unlinkDialog.value,
  (value) => {
    if (
      !value &&
      (unlinkStep.value === UnlinkAccountStep.unlinkFailure ||
        unlinkStep.value === UnlinkAccountStep.unlinkSuccess)
    ) {
      sendEvent(EVENT_NAMES.MODAL_CLOSE, {
        modal_name:
          unlinkStep.value === UnlinkAccountStep.unlinkFailure
            ? 'error_unlinking_account'
            : 'account_unlinked'
      })
    }
  }
)

onMounted(() => {
  if (tokenCode.value) {
    handleSocialLink()
  }
})
</script>

<style scoped lang="scss">
.social-accounts {
  color: $neutral-700;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  &__hint {
    border-top: 1px solid $neutral-100;
    color: $neutral-400;
    padding: $spacing-size-4;
  }
}

.link-account-dialog {
  $dialog: &;

  color: $neutral-900;
  display: flex;
  flex-direction: column;
  gap: $spacing-size-7;
  text-align: center;

  &--success {
    #{$dialog}__icon {
      background-color: $primary-25;
      color: $primary-500;
      display: flex;
    }

    #{$dialog}__actions {
      display: flex;

      &__cancel {
        display: none;
      }
    }
  }

  &--error {
    #{$dialog}__icon {
      background-color: $error-50;
      color: $error-500;
      display: flex;
    }

    #{$dialog}__actions {
      display: flex;
    }
  }

  &--loading {
    #{$dialog}__actions {
      pointer-events: none;

      &__confirm {
        background-color: $error-700;
        pointer-events: none;
      }
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $spacing-size-2;
  }

  &__title {
    font-weight: $font-regular;
  }

  &__description {
    color: $neutral-600;
  }

  &__icon {
    align-items: center;
    border-radius: 100%;
    display: none;
    height: $spacing-size-8;
    justify-content: center;
    width: $spacing-size-8;
  }

  &__actions {
    display: none;
    flex-direction: column;
    gap: $spacing-size-2;

    &__loader {
      height: 30%;
      margin: auto;
    }
  }
}
</style>
