<template>
  <div class="payments">
    <header class="payments__header">
      <p class="payments__header__title text-md">{{ $t('general_page_title_payments') }}</p>
      <p class="payments__header__subtitle text-xs">{{ $t('general_page_subtitle_payments') }}</p>
    </header>
    <div class="payments__content">
      <img src="@/assets/images/png/mm_zexel.png" alt="" class="payments__content__logo" />
      <div class="payments__content__info">
        <p class="payments__content__title heading-xs">{{ $t('payments_info_title') }}</p>
        <p
          class="payments__content__text text-md"
          v-html="$t('payments_info_text_1', { variable1: userProfile.email })"
        />
        <p class="payments__content__text text-md" v-html="$t('payments_info_text_2')" />
        <p class="payments__content__text text-md">{{ $t('payments_info_text_3') }}</p>
      </div>
      <div class="payments__content__actions">
        <a
          href="https://pay.zexel.io/auth/signup"
          target="_blank"
          class="payments__content__link btn"
          @click="handleZexelClick"
        >
          {{ $t('go_to_zexel') }}
          <i class="payments__content__link__icon mkt-arrow-right" />
        </a>
        <p class="payments__content__hint text-xs">{{ $t('payments_hint_zexel') }}</p>
      </div>
    </div>
    <help-me link="https://hello.multimarkts.com/base-conocimientos/sistema-de-pagos" />
  </div>
</template>
<script setup lang="ts">
import { EVENT_NAMES } from '@/types/events'
import { useUserStore } from '@/stores/user'

definePage({
  meta: {
    layout: 'app',
    pageTitleKey: 'general_page_title_payments'
  },
  name: 'my_payments'
})

const { sendEvent } = useMetrics()
const userStore = useUserStore()
const micrositeStore = useMicrositeStore()

const userProfile = computed(() => userStore.getProfile())

const handleZexelClick = () => {
  micrositeStore.setIsZexelClicked(true)
  sendEvent(EVENT_NAMES.LINK_CLICK, {
    link_id: 'go_to_zexel',
    link_url: 'https://pay.zexel.io/auth/signup',
    link_type: 'button'
  })
}
</script>
<style lang="scss" scoped>
.payments {
  display: flex;
  flex-direction: column;
  gap: $spacing-size-7;
  padding: $spacing-size-4 $spacing-size-5;

  &__header {
    &__title {
      color: $neutral-700;
      font-weight: $font-semibold;
    }

    &__subtitle {
      color: $neutral-500;
      font-weight: $font-medium-new;
    }

    margin-bottom: $spacing-size-7;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $spacing-size-6;
    justify-content: center;
    text-align: center;

    &__logo {
      max-width: $base-unit * 11.25;
      width: 100%;
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: $spacing-size-4;
    }

    &__title {
      color: $neutral-900;
      font-weight: $font-medium-new;
      margin-bottom: -$spacing-size-2;
    }

    &__text {
      color: $neutral-500;
      font-weight: $font-medium-new;
    }

    &__link {
      background-color: $zexel;
      color: $white;
      margin-bottom: $spacing-size-2;

      &__icon {
        transform: rotate(-45deg);
      }
    }

    &__hint {
      color: $neutral-400;
      font-size: $font-size-2;
    }
  }
}
</style>
