import { z } from 'zod'

export const micrositeSchema = z.object({
  avatarUrl: z.string(),
  createdAt: z.string(),
  id: z.string(),
  isSocialMediaLinked: z.boolean(),
  name: z.string(),
  slug: z.string(),
  socialMediaHandlers: z
    .object({
      handler: z.string(),
      platform: z.string(),
      url: z.string()
    })
    .array(),
  type: z.string(),
  updatedAt: z.string(),
  isOnboardingCompleted: z.boolean().optional().default(false)
})

export type Microsite = z.infer<typeof micrositeSchema>
