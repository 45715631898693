<template>
  <div class="home">
    <UserCard />

    <section v-if="hasAlerts" class="home__alerts alerts">
      <router-link to="/payments" v-if="!isZexelClicked" @click="handleClickZexel">
        <div class="alerts__social">
          <i class="alerts__social__icon mkt-euro" />
          <div>
            <p class="alerts__social__text text-md">{{ $t('zexel_alert_link_title') }}</p>
            <p class="alerts__social__hint text-sm">{{ $t('zexel_alert_link_subtitle') }}</p>
          </div>
          <span class="alerts__social__link">
            <i class="alerts__social__icon alerts__social__icon--button mkt-arrow-right" />
          </span>
        </div>
      </router-link>
      <router-link v-if="!hasMicrositeSocialMediaLinked" to="/social-accounts">
        <div class="alerts__social">
          <i class="alerts__social__icon mkt-social-media" />
          <span class="alerts__social__text text-md">{{ $t('social_accounts_alert_text') }}</span>
          <span class="alerts__social__link">
            <i class="alerts__social__icon alerts__social__icon--button mkt-arrow-right" />
          </span>
        </div>
      </router-link>
    </section>

    <section v-if="showMetrics" class="home__overview">
      <header class="home__overview__header">
        <span class="home__overview__header__title text-md">{{ t('general_overview') }}</span>
        <small class="home__overview__header__description">
          {{ t('home_overview_last_seven_days') }}</small
        >
      </header>
      <div class="home__overview__cards text-xs">
        <div class="home__overview__card">
          <div class="home__overview__card__icon">
            <i class="mkt-user" />
          </div>
          <span class="text-xl"> {{ totalVisits }} </span>
          <span class="home__overview__card__description text-xs">
            {{ t('metrics_visits_total') }}
          </span>
        </div>
        <div class="home__overview__card">
          <div class="home__overview__card__icon">
            <i class="mkt-shopping-bag-alt" />
          </div>
          <span class="text-xl"> {{ totalProductClicks }} </span>
          <span class="home__overview__card__description text-xs">
            {{ t('metrics_products_clicks') }}
          </span>
        </div>
        <div class="home__overview__card">
          <div class="home__overview__card__icon">
            <i class="mkt-euro" />
          </div>
          <span class="text-xl"> {{ totalProductsCTR }}% </span>
          <span class="home__overview__card__description text-xs">
            {{ t('metrics_products_ctr') }}
          </span>
        </div>
      </div>
    </section>
    <section v-else class="home__overview-empty">
      <header class="home__overview-empty__header">
        <span class="home__overview-empty__header__title text-md">
          {{ t('general_overview') }}
        </span>
      </header>
      <div class="home__overview-empty__content">
        <div class="home__overview-empty__content__icons">
          <i class="mkt-user"></i>
          <i class="mkt-euro" />
          <i class="mkt-shopping-bag"></i>
        </div>
        <p class="text-lg">
          {{ t('home_overview_empty_title') }}
        </p>
        <p class="home__overview-empty__content__description text-sm">
          {{ t('home_overview_empty_description') }}
        </p>
      </div>
    </section>

    <section class="home__share">
      <img
        src="@/assets/images/svg/social_medias_group.svg"
        alt="social media group logos"
        class="home__share__image"
      />
      <p class="home__share__title text-md">{{ $t('share_promotes_title') }}</p>
      <p class="text-sm home__share__subtitle">{{ $t('share_promotes_subtitle') }}</p>
      <button class="home__share__action btn btn--content" @click="handleShare">
        {{ $t('general_share') }}
        <i class="home__share__icon mkt-share" />
      </button>
    </section>

    <section class="home__posts">
      <header class="home__posts__header">
        <span class="home__posts__header__title text-md">{{ t('general_posts') }}</span>
        <small class="home__posts__header__subtitle text-xs">
          {{ t('home_posts_description') }}</small
        >
      </header>
      <div class="home__posts__grid">
        <img
          v-for="post in posts"
          class="home__posts__grid__item"
          :src="getMediaPost(post) ?? ''"
          :key="post.id"
          :alt="`${post.title}`"
          @click="onPostClick(post.id)"
        />
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import type { PostUI } from '@/stores/posts'
import { useUserStore } from '@/stores/user'
import { useMetricsStore } from '@/stores/metrics'
/*import { SnackbarType } from '@/types/snackbar'*/
import { ENV } from '@/constants/env'
import { PostType } from '@/types/general'
import { useMetrics } from '@/composables/useMetrics'
import { EVENT_NAMES } from '@/types/events'
import { useMicrositeStore } from '@/stores/microsite'

definePage({
  meta: {
    layout: 'app'
  },
  name: 'admin_global_position'
})

const router = useRouter()
const route = useRoute()
const uiStore = useUiStore()
const userStore = useUserStore()
const micrositeStore = useMicrositeStore()
const postsStore = usePostsStore()
const metricsStore = useMetricsStore()
const { t, locale } = useI18n()
const { sendEvent } = useMetrics()

const user = computed(() => userStore.getProfile())
const microsite = computed(() => micrositeStore.getMicrosite())
const hasMicrositeSocialMediaLinked = computed(() => micrositeStore.getIsSocialMediaLinked())
const isOnboardingCompleted = computed(() => micrositeStore.microsite.isOnboardingCompleted)
const posts = computed(() => postsStore.getUserPublishedPosts())
const totalVisits = computed(() => metricsStore.getTotalValues.totalVisits)
const totalProductClicks = computed(() => metricsStore.getTotalValues.totalProductClicks)
const totalProductsCTR = computed(() => metricsStore.getTotalValues.productCtr)
const micrositeUrl = computed(() => `${ENV.multimarktsAppDomain}${locale.value}/${user.value.slug}`)
const showMetrics = computed(() => Boolean(metricsStore.getMetrics.length))
const isZexelClicked = computed(() => micrositeStore.getIsZexelClicked())
const hasAlerts = computed(() => !isZexelClicked.value || !hasMicrositeSocialMediaLinked)
const isFromEmail = computed(() => route.query.fromEmail)

const stopUserWatcher = watch(
  () => [user.value, microsite.value],
  ([_user, _microsite]) => {
    if (_user.id.length && Object.keys(_microsite).length) {
      stopUserWatcher()
      if (!isOnboardingCompleted.value) {
        router.push({
          path: '/onboarding'
        })
      }
    }
  }
)

const handleShare = async () => {
  uiStore.setShowShareDialog(true)
  sendEvent(EVENT_NAMES.LINK_CLICK, {
    link_type: 'button',
    link_id: 'share_my_marketplace',
    link_url: micrositeUrl.value
  })
}

const onPostClick = (postId: string) => {
  const url = new URL(`${micrositeUrl.value}/p/${postId}`)

  sendEvent(EVENT_NAMES.LINK_CLICK, {
    link_type: 'posted_content',
    link_url: url.toString(),
    link_id: 'watch_post_in_marketplace',
    content_id: postId
  })

  window.open(url.toString(), '_self')
}

const handleClickZexel = () => {
  sendEvent(EVENT_NAMES.LINK_CLICK, {
    link_type: 'alert',
    link_url: '/payments',
    link_id: 'menu_go_to_payments'
  })
}

const getMediaPost = (post: PostUI) =>
  post.type === PostType.Image ? post.mediaUrl : post.thumbnailUrl || ''

watch(
  () => isFromEmail.value,
  (_value) => {
    if (_value === 't') {
      uiStore.setShowShareDialog(true)
      router.replace({
        path: '/'
      })
    }
  },
  {
    immediate: true
  }
)

window.HubSpotConversations?.widget.load()

onMounted(async () => {
  await Promise.all([micrositeStore.fetchMicrositePosts(), metricsStore.fetchMetrics()])
})

onBeforeUnmount(() => {
  window.HubSpotConversations?.widget.remove()
})
</script>

<style lang="scss" scoped>
$user-card-height: 96px;
$menu-item-height: 72px;

.home {
  background-color: $white;
  color: $neutral-700;
  display: flex;
  flex-direction: column;
  gap: $spacing-size-6;
  height: 100%;
  justify-content: space-between;
  padding: $spacing-size-6 $spacing-size-4;
  width: 100%;

  &__welcome {
    align-items: center;
    border: 1px solid $neutral-200;
    border-radius: $spacing-size-2;
    display: flex;
    flex-direction: column;
    gap: $spacing-size-4;
    padding: $spacing-size-6 $spacing-size-4;

    &__link {
      align-items: center;
      color: $primary-500;
      display: flex;
      font-weight: $font-semibold;
      gap: $spacing-size-2;
    }

    &__description {
      color: $neutral-500;
      text-align: center;
    }

    &__copy-link {
      align-items: center;
      background-color: $neutral-50;
      border-radius: $spacing-size-2;
      display: flex;
      gap: $spacing-size-2;
      height: $spacing-size-10;
      padding: $spacing-size-4;
      width: 100%;

      &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__button {
        align-items: center;
        color: $primary-500;
        cursor: pointer;
        display: flex;
        font-weight: $font-semibold;
        gap: $spacing-size-2;
      }
    }
  }

  &__overview {
    display: flex;
    flex-direction: column;
    gap: $spacing-size-2;

    &__header {
      display: flex;
      justify-content: space-between;

      &__title {
        font-weight: $font-semibold;
      }

      &__description {
        color: $neutral-400;
      }
    }

    &__cards {
      display: flex;
      gap: $spacing-size-2;
    }

    &__card {
      border: 1px solid $neutral-100;
      border-radius: $spacing-size-1;
      display: flex;
      flex-direction: column;
      gap: $spacing-size-3;
      padding: $spacing-size-3;
      width: 100%;

      &__icon {
        align-items: center;
        background-color: $neutral-100;
        border-radius: 100%;
        display: flex;
        height: $spacing-size-6;
        justify-content: center;
        width: $spacing-size-6;
      }

      &__description {
        color: $neutral-400;
        font-weight: $font-light;
      }
    }
  }

  &__overview-empty {
    display: flex;
    flex-direction: column;
    gap: $spacing-size-2;

    &__header {
      &__title {
        font-weight: $font-semibold;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: $spacing-size-2;
      padding: $spacing-size-6 $spacing-size-4;
      text-align: center;

      &__icons {
        align-items: center;
        color: $neutral-300;
        display: flex;
        gap: $spacing-size-2;
        justify-content: center;

        i:nth-child(2) {
          align-items: center;
          background-color: $neutral-100;
          border-radius: 100%;
          color: $neutral-700;
          display: flex;
          height: $spacing-size-8;
          justify-content: center;
          width: $spacing-size-8;
        }
      }

      &__description {
        color: $neutral-500;
      }
    }
  }

  &__posts {
    display: flex;
    flex-direction: column;
    gap: $spacing-size-4;

    &__header {
      display: flex;
      flex-direction: column;

      &__title {
        font-weight: $font-semibold;
      }

      &__subtitle {
        color: $neutral-500;
      }
    }

    &__grid {
      display: grid;
      grid-gap: $spacing-size-2;
      grid-template-columns: repeat(3, 1fr);

      &__item {
        aspect-ratio: 1;
        border-radius: $spacing-size-2;
        cursor: pointer;
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }
  }

  &__share {
    background-color: $brand-blue-500;
    border-radius: $spacing-size-2;
    padding: $spacing-size-4;

    &__image {
      height: $spacing-size-10;
      margin: (-$spacing-size-1) 0 $spacing-size-1 (-$spacing-size-2);
      object-fit: contain;
      width: $base-unit * 9.125;
    }

    &__title {
      color: $neutral-900;
      font-weight: $font-semibold;
      margin-bottom: $spacing-size-1;
    }

    &__subtitle {
      color: $neutral-900;
      font-weight: $font-medium-new;
      margin-bottom: $spacing-size-4;
    }

    &__action {
      font-size: $font-size-4;
      height: auto;
      line-height: $line-height-3;
      padding: $spacing-size-3 0;
    }
  }
}
</style>
