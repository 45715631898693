import type { GenericError } from '@/types/error'
import router from '@/lib/router'

export default function useError(error: Partial<GenericError>) {
  const uiStore = useUiStore()

  uiStore.setError(error)

  router.replace({
    path: '/error'
  })
}
