<template>
  <section class="onboarding">
    <component
      v-if="activeStepIndex >= 0"
      :is="activeStep.component"
      :backgroundColor="activeStep.backgroundColor"
      :title="activeStep.title"
      :subtitle="activeStep.subtitle"
    >
      <template v-if="activeStep.slot">
        <component
          :is="activeStep.slot"
          :delay="activeStep.delay"
          :code="code"
          @next="handleNext"
        ></component>
      </template>
    </component>
  </section>
</template>

<script setup lang="ts">
import SocialLink from '@/components/onboarding/SocialLink.vue'
import Step from '@/components/onboarding/Step.vue'
import SyncingPosts from '@/components/onboarding/SyncingPosts.vue'
import Congratulations from '@/components/onboarding/Congratulations.vue'
import StorefrontCreate from '@/components/onboarding/StorefrontCreate.vue'
import AnalyzePosts from '@/components/onboarding/AnalyzePosts.vue'
import SaveBrands from '@/components/onboarding/SaveBrands.vue'
import { useUserStore } from '@/stores/user'
import { ENV } from '@/constants/env'

export interface StepSlotProps {
  delay?: number
  code?: string
}

definePage({
  name: 'ob_router'
})

const { locale } = useI18n()
const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const micrositeStore = useMicrositeStore()

const activeStepIndex = ref<number>(-1)
const code = ref<string>('')
const onboardingFunnelSteps = [
  {
    name: 'social link',
    component: markRaw(SocialLink)
  },
  {
    name: 'storefront create',
    component: markRaw(Step),
    slot: markRaw(StorefrontCreate),
    backgroundColor: '#ffd438', // brand-yellow-500
    title: 'onboarding_storefront_create_title',
    delay: 2000
  },
  {
    name: 'syncing posts',
    component: markRaw(Step),
    slot: markRaw(SyncingPosts),
    backgroundColor: '#97bcff', // brand-blue-500
    title: 'onboarding_microsite_create_title',
    delay: 5000
  },
  {
    name: 'save brands',
    component: markRaw(Step),
    slot: markRaw(SaveBrands),
    backgroundColor: '#ff92c6', // brand-pink-500
    title: 'onboarding_save_brands_title',
    delay: 5000
  },
  {
    name: 'analyze posts',
    component: markRaw(Step),
    slot: markRaw(AnalyzePosts),
    backgroundColor: '#97bcff', // brand-blue-500,
    title: 'onboarding_analyze_posts_title'
  },
  {
    name: 'congratulations',
    component: markRaw(Step),
    slot: markRaw(Congratulations),
    backgroundColor: '#00d187', // primary-500
    title: 'onboarding_congratulations_title',
    subtitle: 'onboarding_congratulations_subtitle'
  }
]

const isOnboardingCompleted = computed(() => micrositeStore.getMicrosite().isOnboardingCompleted)
const isSocialMediaLinked = computed(() => micrositeStore.getIsSocialMediaLinked())
const onboardingProgress = computed(
  () => `${(100 * activeStepIndex.value) / onboardingFunnelSteps.length}dvw`
)
const activeStep = computed(() => onboardingFunnelSteps[activeStepIndex.value])

const handleNext = async () => {
  if (activeStepIndex.value < onboardingFunnelSteps.length - 1) {
    activeStepIndex.value++
  } else {
    await micrositeStore.completeOnboarding()
    window.location.href = `${ENV.multimarktsAppDomain}${locale.value}/${userStore.getProfile().slug}?wow=true`
  }
}

onMounted(async () => {
  await userStore.fetchUserProfile()
  await micrositeStore.fetchMicrosite()

  if (isOnboardingCompleted.value) {
    router.replace('/')
  }

  if (route.query.code) {
    code.value = route.query.code as string
    router.replace('/onboarding')
    activeStepIndex.value = 1
  } else if (isSocialMediaLinked.value) {
    await micrositeStore.fetchMicrositePosts()
    activeStepIndex.value = 3
  } else {
    activeStepIndex.value = 0
  }
})
</script>

<style scoped lang="scss">
.onboarding {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  &::after {
    animation: progressbar-appear 1s;
    animation-fill-mode: forwards;
    background-color: $white;
    bottom: 0;
    content: '';
    height: 5px;
    left: 0;
    position: absolute;
    transition: width 0.3s;
    width: 0dvw;
    width: v-bind(onboardingProgress);
  }

  & > *,
  :deep(.onboarding__content) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  :deep(.onboarding__content) {
    justify-content: center;
  }
}

@keyframes progressbar-appear {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
