import { defineStore } from 'pinia'
import { StoreNamespaces, StoreStorageKeys } from '@/types/store'
import type { UserCredentials } from '@/models/user-credentials.model'
import { AuthService } from '@/api/auth.service'
import { FIREBASE_AUTH_PROVIDERS, FirebaseAuth } from '@/lib/firebase'
import { createCookieStorage } from '@/lib/storage'
import { ErrorType } from '@/types/error'

export const useAuthStore = defineStore(
  StoreNamespaces.auth,
  () => {
    const authService = new AuthService()
    const firebaseAuth = new FirebaseAuth()
    const router = useRouter()

    const userCredentials = ref<UserCredentials>({
      token: null
    })

    const getUserToken = () => userCredentials.value.token

    const loginWithGoogle = async () => {
      try {
        return await firebaseAuth.login(FIREBASE_AUTH_PROVIDERS.GOOGLE)
      } catch (e) {
        console.log('e', 'Error login with google')
        return null
      }
    }

    const fetchUserToken = async (token: string) => {
      try {
        const userCredentials = await authService.setUserToken(token)
        setUserToken(userCredentials.token)
      } catch (e: any) {
        if (e.response?.status === 404) {
          useError({
            type: ErrorType.warning,
            title: 'auth_mail_not_registered',
            message: 'auth_mail_not_registered_message',
            buttonLink: 'mailto:support@multimarkts.com',
            buttonText: 'general_contact'
          })
        }

        throw new Error('Error fetching user token from api')
      }
    }

    const logout = async (withRedirect = true) => {
      setUserToken(null)
      if (withRedirect) {
        await router.push({
          path: '/access'
        })
      }
      useUiStore().resetStores()
    }

    const setUserToken = (token: string | null) => {
      userCredentials.value.token = token
    }

    return {
      userCredentials,
      getUserToken,
      fetchUserToken,
      loginWithGoogle,
      logout
    }
  },
  {
    persist: {
      storage: createCookieStorage({}),
      paths: ['userCredentials'],
      key: StoreStorageKeys.auth
    }
  }
)
