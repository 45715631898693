<template>
  <div class="feedback">
    <FrillEmbedWidget
      :widget-key="FRILL_WIDGET_KEYS.ideas.mobile"
      :sso-token="ssoToken"
      :title="t('feedback_mobile_title')"
      :subtitle="t('feedback_mobile_subtitle')"
    />
  </div>
</template>

<script setup lang="ts">
import { FRILL_WIDGET_KEYS } from '@/constants/frill'

definePage({
  meta: {
    layout: 'app',
    pageTitleKey: 'general_page_title_feedback',
    name: 'admin_feedback'
  }
})

const { t } = useI18n()

const ssoToken = computed(() => useUserStore().getFeedbackToken)
</script>

<style lang="scss" scoped>
.feedback {
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>
