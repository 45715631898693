import { z } from 'zod'

export const productSchema = z
  .object({
    id: z.string(),
    currency: z.enum(['$', '€']),
    description: z.string(),
    score: z.number().optional(),
    name: z.string(),
    price: z.union([z.string(), z.number()]),
    discount: z.number(),
    linkUrl: z.string(),
    imageS3Url: z.string(),
    brandName: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    isActive: z.boolean().optional().default(true)
  })
  .transform((product) => ({
    id: product.id,
    name: product.name,
    price: product.price,
    discount: product.discount,
    url: product.linkUrl,
    media: product.imageS3Url,
    brand: product.brandName,
    description: product.description,
    currency: product.currency,
    score: product.score
  }))

export type Product = z.infer<typeof productSchema>
