<template>
  <Suspense>
    <MetricsView />
    <template #fallback>
      <Loader />
    </template>
  </Suspense>
</template>

<script setup lang="ts">
definePage({
  meta: {
    layout: 'app',
    pageTitleKey: 'general_page_title_metrics'
  },
  name: 'metrics_overview'
})
</script>
