import { defineStore } from 'pinia'
import { StoreNamespaces } from '@/types/store'
import { UserService } from '@/api/user.service'
import type { UserProfile } from '@/models/profile.model'
import type { Product } from '@/models/products.model'
import { useMicrositeStore } from '@/stores/microsite'
import { usePostsStore } from '@/stores/posts'

export interface ProductUi extends Product {
  isSelected: boolean
}

export const useUserStore = defineStore(StoreNamespaces.user, () => {
  const userService = new UserService()
  const authStore = useAuthStore()
  const micrositeStore = useMicrositeStore()
  const postsStore = usePostsStore()

  const id = ref<string>('')
  const username = ref<string>('')
  const email = ref<string>('')
  const avatar = ref<string>('')
  const feedbackToken = ref<UserProfile['feedbackToken']>(null)

  const fetchUserProfile = async () => {
    if (!id.value) {
      try {
        const profile = await userService.getUserProfile()
        setUserProfile(profile)

        if (!profile.feedbackToken) {
          await createFeedbackToken()
        }
      } catch (e) {
        console.log('Error fetching user.', e)
        authStore.logout()
      }
    }
  }

  const createFeedbackToken = async () => {
    const { feedbackToken: token } = await userService.createFeedbackToken()
    feedbackToken.value = token
  }

  const deleteUser = async () => {
    await userService.deletetUserProfile()
    await authStore.logout(false)
  }

  const setUserProfile = (profile: UserProfile): void => {
    id.value = profile.id
    avatar.value = profile.avatar
    username.value = profile.username
    email.value = profile.email
    feedbackToken.value = profile.feedbackToken
  }

  const getProfile = () => {
    return {
      id: id.value,
      username: micrositeStore.microsite?.name,
      email: email.value,
      avatar: avatar.value,
      posts: postsStore.posts,
      slug: micrositeStore.microsite?.slug
    }
  }

  const getFeedbackToken = computed(() => feedbackToken.value)

  const $reset = () => {
    setUserProfile({
      id: '',
      avatar: '',
      username: '',
      feedbackToken: '',
      email: ''
    } as UserProfile)
  }

  return {
    id,
    username,
    avatar,
    getFeedbackToken,
    deleteUser,
    fetchUserProfile,
    getProfile,
    $reset
  }
})
