import { ofetch } from 'ofetch'

export default abstract class Http {
  private readonly baseUrl: string
  private readonly http

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
    this.http = ofetch.create({
      baseURL: this.baseUrl,
      onRequest(context): Promise<void> | void {
        const authStore = useAuthStore()
        const token = authStore.getUserToken()
        if (token) {
          context.options.headers = {
            ...context.options.headers,
            Authorization: `Bearer ${token}`
          }
        }
      }
    })
  }

  async get(path: string, customHeaders: Record<string, any> = {}) {
    return await this.http(path, {
      headers: {
        ...customHeaders
      }
    })
  }

  async post(path: string, body: Record<string, any>, customHeaders: Record<string, any> = {}) {
    return await this.http(path, {
      method: 'POST',
      body,
      headers: {
        ...customHeaders
      }
    })
  }

  async patch(path: string, body: Record<string, any>, customHeaders: Record<string, any> = {}) {
    return await this.http(path, {
      method: 'PATCH',
      body,
      headers: {
        ...customHeaders
      }
    })
  }

  async put(path: string, customHeaders: Record<string, any> = {}) {
    return await this.http(path, {
      method: 'PUT',
      headers: {
        ...customHeaders
      }
    })
  }

  async delete(path: string, customHeaders: Record<string, any> = {}) {
    return await this.http(path, {
      method: 'DELETE',
      headers: {
        ...customHeaders
      }
    })
  }
}
