export enum ErrorType {
  error = 'error',
  warning = 'warning',
  info = 'info'
}

export interface GenericError {
  title: string
  message: string
  buttonLink: string
  buttonText: string
  type: ErrorType
  isCritical: boolean
  delayTime: number
}
