export enum SnackbarType {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success'
}

export interface Snackbar {
  id: number
  type: SnackbarType
  message: string
  messageVariables?: Record<string, string>
  canBeClosed: boolean
}
