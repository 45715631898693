<template>
  <div class="not-found-error">
    <div class="not-found-error__content">
      <h1 class="heading-xl">404</h1>
      <h2 class="not-found-error__content__title text-lg">
        {{ t('general_not_found_error') }}
      </h2>
      <p class="not-found-error__content__subtitle text-md">
        {{ t('general_not_found_error_subtitle') }}
      </p>
    </div>
    <RouterLink to="/" class="btn btn--primary">{{ t('general_error_action') }}</RouterLink>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

definePage({
  name: 'route_name_not_found'
})
</script>

<style scoped lang="scss">
.not-found-error {
  align-items: center;
  color: $neutral-900;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: $spacing-size-7 $spacing-size-6;

  &__icon {
    font-size: $font-size-8;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: $spacing-size-1;
    justify-content: center;
    padding: $spacing-size-4;
    text-align: center;

    &__title {
      font-weight: $font-regular;
    }
  }
}
</style>
