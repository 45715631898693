<template>
  <section class="posts">
    <header class="posts__header">
      <h1 class="text-md">{{ $t('posts_page_title') }}</h1>
      <p class="posts__header__subtitle text-xs">{{ $t('posts_page_subtitle') }}</p>
      <div class="posts__header__actions">
        <button
          v-if="hasMicrositeSocialMediaLinked"
          type="button"
          :class="[
            'posts__header__action',
            {
              'posts__header__action--loading': isRequestingNewPosts,
              'posts__header__action--disabled': isRequestNewPostsDisabled
            }
          ]"
          @click="handleForceUpdate"
        >
          <i
            class="posts__header__action__icon posts__header__action__icon--update mkt-refresh"
          ></i>
        </button>
        <button
          v-if="false"
          type="button"
          class="posts__header__action"
          @click="handleDisplayChange"
        >
          <i
            :class="[
              'posts__header__action__icon',
              'posts__header__action__icon--display',
              showGridMode ? 'mkt-list' : 'mkt-grid'
            ]"
          ></i>
        </button>
      </div>
    </header>
    <router-link v-if="!hasMicrositeSocialMediaLinked" to="/social-accounts">
      <div class="alerts__social">
        <i class="alerts__social__icon mkt-social-media" />
        <span class="alerts__social__text text-md">{{ $t('social_accounts_alert_text') }}</span>
        <span class="alerts__social__link">
          <i class="alerts__social__icon alerts__social__icon--button mkt-arrow-right" />
        </span>
      </div>
    </router-link>
    <div class="posts__filters">
      <ul class="posts__filters__list">
        <li
          v-for="(filter, index) in filtersList"
          :key="`filter-${filter.label}-${index}`"
          class="posts__filters__list__item"
          @click="handleFiltersClick(index)"
        >
          <span
            :class="[
              'posts__filters__list__item__link text-sm',
              {
                'posts__filters__list__item__link--active': activeFilterIndex === index
              }
            ]"
          >
            {{ $t(filter.label) }} {{ `(${filter.count})` }}
          </span>
        </li>
      </ul>
    </div>
    <ol
      :class="[
        'posts__list',
        {
          'posts__list--grid': showGridMode
        }
      ]"
    >
      <li
        v-for="(post, index) in filteredPosts"
        :key="`post-${post.id}-${index}`"
        :class="[
          'posts__list__item',
          { 'posts__list__item--recently-published': postsStatus[post.id].hasBeenRecentlyPublished }
        ]"
      >
        <PostCard
          :post="post"
          :has-badge="post.status !== PostStatus.Skipped"
          @click="handlePostCardClick($event, post)"
        >
          <template v-slot:action>
            <loader
              v-if="post.status === PostStatus.Draft || postsStatus[post.id].isRequesting"
              class="post-card__action"
              :size="24"
              :stroke-width="3"
              :bar-color="'#f2f4f7'"
            ></loader>
            <checkbox
              v-else
              :model-value="post.status === PostStatus.Published"
              :name="`${post.id}-${index}`"
              :disabled="isPostToggleDisabled(post)"
              @click="handlePostPublishToggleClick(post)"
              @update:model-value="handlePostPublishToggle(post.id)"
            />
          </template>
        </PostCard>
      </li>
    </ol>
    <div v-if="hasMicrositeSocialMediaLinked && hasOlderPosts" class="posts__bottom-actions">
      <loader v-if="isRequestingOldPosts" class="posts__bottom-actions__loader" />
      <button
        v-else
        type="button"
        class="btn btn--outline"
        :disabled="isRequestOldPostsDisabled"
        @click="handleRequestOlderPosts"
      >
        {{ $t('posts_page_get_older_posts_button') }}
      </button>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { PostUI } from '@/stores/posts'
import { useUserStore } from '@/stores/user'
import PostCard from '@/components/PostCard.vue'
import { ENV } from '@/constants/env'
import { EVENT_NAMES } from '@/types/events'
import { useMetrics } from '@/composables/useMetrics'
import { usePostsStore } from '@/stores/posts'
import { SnackbarType } from '@/types/snackbar'
import { PostStatus } from '@/types/general'

interface PostUiStatus {
  isRequesting: boolean
  hasBeenRecentlyPublished: boolean
}

const uiStore = useUiStore()
const userStore = useUserStore()
const postsStore = usePostsStore()
const micrositeStore = useMicrositeStore()

definePage({
  meta: {
    layout: 'app'
  },
  name: 'my_posts'
})

const { locale } = useI18n()
const { sendEvent } = useMetrics()

const activeFilterIndex = ref<number>(0)
const isRequestingNewPosts = ref<boolean>(false)
const isRequestNewPostsDisabled = ref<boolean>(false)
const showGridMode = ref<boolean>(false)
const minTimeToManualRefresh = 15 * 60 * 1000
const remainingTimeToRefresh = ref<number>(0)
const isRequestingOldPosts = ref<boolean>(false)
const remainingTimeToRequestOldPosts = ref<number>(0)
const isRequestOldPostsDisabled = ref<boolean>(false)
const minTimeGapBetweenPublishing = 24 * 60 * 60 * 1000

const user = computed(() => userStore.getProfile())

const hasMicrositeSocialMediaLinked = computed(() => micrositeStore.getIsSocialMediaLinked())
const micrositeUrl = computed(() => `${ENV.multimarktsAppDomain}${locale.value}/${user.value.slug}`)
const postsStatus = ref<Record<string, PostUiStatus>>({})
const posts = computed(() => postsStore.getMicrositePosts())
const filteredPosts = computed(() =>
  posts.value.filter((post) => {
    return (
      activeFilterIndex.value === 0 ||
      (activeFilterIndex.value === 1 && post.status === PostStatus.Published) ||
      (activeFilterIndex.value === 2 && post.status === PostStatus.Skipped)
    )
  })
)

const filtersList = computed(() => {
  return posts.value.reduce(
    (acc, post) => {
      acc[0].count++
      if (post.status === PostStatus.Published) {
        acc[1].count++
      }
      if (post.status === PostStatus.Skipped) {
        acc[2].count++
      }
      return acc
    },
    [
      {
        label: 'filter_all',
        count: 0
      },
      {
        label: 'filter_published',
        count: 0
      },
      {
        label: 'filter_skipped',
        count: 0
      }
    ]
  )
})

watch(
  () => posts.value,
  (newPosts) => {
    postsStatus.value = newPosts.reduce((acc, post) => {
      if (!acc[post.id]) {
        acc[post.id] = {
          isRequesting: false,
          hasBeenRecentlyPublished: false
        }
      }
      return acc
    }, postsStatus.value)
  },
  {
    immediate: true
  }
)

const hasOlderPosts = computed(() => micrositeStore.hasOldPostsToRecover)

const isPostRecentlyPublished = (post: PostUI): boolean =>
  Date.now() - new Date(post.updatedAt).getTime() < minTimeGapBetweenPublishing

const isPostToggleDisabled = (post: PostUI): boolean =>
  isPostRecentlyPublished(post) && post.status !== PostStatus.Published

const getPostDirectLink = (postId: string) => `${micrositeUrl.value}/p/${postId}`

const handleForceUpdate = async () => {
  if (isRequestNewPostsDisabled.value) {
    uiStore.showRateLimitPostUpdateSnackbar(remainingTimeToRefresh.value)

    sendEvent(EVENT_NAMES.SYNC_IG_FEED, {
      sync_enabled: false
    })
  } else {
    isRequestNewPostsDisabled.value = true
    isRequestingNewPosts.value = true

    const refreshSnackbar = uiStore.addSnackbar(
      'manual_refresh_posts_start',
      SnackbarType.info,
      {},
      false
    )

    const postsSnapshot: Array<PostUI> = JSON.parse(JSON.stringify(posts.value))
    const retryAfter = await micrositeStore.updateNewMicrositePosts()

    uiStore.removeSnackbar(refreshSnackbar)

    updateRecentlyPublished(postsSnapshot)
    showBatchUpdateSnackbar(postsSnapshot, retryAfter)

    sendEvent(EVENT_NAMES.SYNC_IG_FEED, {
      sync_enabled: !retryAfter
    })

    isRequestingNewPosts.value = false
    remainingTimeToRefresh.value = retryAfter || minTimeToManualRefresh

    setTimeout(() => {
      remainingTimeToRefresh.value = 0
      isRequestNewPostsDisabled.value = false
    }, remainingTimeToRefresh.value)
  }
}

const handleDisplayChange = () => {
  showGridMode.value = !showGridMode.value
}
const handleFiltersClick = (filterIndex: number) => {
  activeFilterIndex.value = filterIndex
}

const handlePostCardClick = (event: MouseEvent, post: PostUI) => {
  if (
    post.status === PostStatus.Published &&
    !(event.target as HTMLElement)?.closest('.post-card__action')
  ) {
    sendEvent(EVENT_NAMES.LINK_CLICK, {
      link_type: 'posted_content',
      link_id: 'watch_post_in_marketplace',
      link_url: getPostDirectLink(post.id),
      content_id: post.id
    })
    window.location.href = getPostDirectLink(post.id)
  }
}

const handlePostPublishToggle = async (postId: string) => {
  const post = posts.value.find((_post) => _post.id === postId)
  if (post) {
    postsStatus.value[post.id].isRequesting = true
    const snackbarId = uiStore.addSnackbar(
      'my_posts_syncing_one_post',
      SnackbarType.info,
      {},
      false
    )
    const productQuantity = await postsStore.updatePostStatus(postId)
    uiStore.removeSnackbar(snackbarId)
    if (post.status === PostStatus.Skipped) {
      if (productQuantity) {
        uiStore.addSnackbar('my_posts_one_posts_published', SnackbarType.success)
        setTimeout(() => {
          postsStatus.value[post.id].hasBeenRecentlyPublished = true
        }, 0)
      } else {
        uiStore.addSnackbar('my_posts_empty_results_syncing', SnackbarType.warning, {})
      }
    }

    postsStatus.value[post.id].isRequesting = false
  }
}

const handlePostPublishToggleClick = (post: PostUI) => {
  sendEvent(EVENT_NAMES.CONTENT_STATUS_TOGGLE, {
    content_status: post.status as PostStatus.Published | PostStatus.Skipped,
    content_source: 'instagram',
    content_type: 'post',
    content_id: post.id,
    toggle_enabled: !isPostToggleDisabled(post)
  })
  if (isPostToggleDisabled(post)) {
    uiStore.addSnackbar('my_posts_disabled_post_toggle', SnackbarType.warning, {})
  }
}

const handleRequestOlderPosts = async () => {
  if (isRequestOldPostsDisabled.value) {
    uiStore.showRateLimitPostUpdateSnackbar(remainingTimeToRequestOldPosts.value)

    sendEvent(EVENT_NAMES.SYNC_IG_FEED, {
      sync_enabled: false
    })
  } else {
    isRequestingOldPosts.value = true
    isRequestOldPostsDisabled.value = true

    const refreshSnackbar = uiStore.addSnackbar(
      'manual_refresh_posts_start',
      SnackbarType.info,
      {},
      false
    )

    const postsSnapshot: Array<PostUI> = JSON.parse(JSON.stringify(posts.value))
    const retryAfter = await micrositeStore.updatePreviousMicrositePosts()

    uiStore.removeSnackbar(refreshSnackbar)

    updateRecentlyPublished(postsSnapshot)
    showBatchUpdateSnackbar(postsSnapshot, retryAfter)

    sendEvent(EVENT_NAMES.POSTS_LOAD_MORE, {
      load_available: !!retryAfter
    })

    isRequestingOldPosts.value = false
    remainingTimeToRequestOldPosts.value = retryAfter || minTimeToManualRefresh
    isRequestOldPostsDisabled.value = false
  }
}

const showBatchUpdateSnackbar = (postsSnapshot: Array<PostUI>, retryAfter: number = 0) => {
  const postDifference = posts.value.length - postsSnapshot.length
  if (retryAfter) {
    uiStore.showRateLimitPostUpdateSnackbar(retryAfter)
  } else if (postDifference) {
    const prevPublishedPosts = postsSnapshot.filter((post) => post.status === PostStatus.Published)
    const publishedPostsDifference =
      posts.value.filter((post) => post.status === PostStatus.Published).length -
      prevPublishedPosts.length
    if (publishedPostsDifference) {
      uiStore.addSnackbar(
        publishedPostsDifference === 1
          ? 'my_posts_one_posts_published'
          : 'my_posts_more_than_one_post_published',
        SnackbarType.success,
        {
          variable1: publishedPostsDifference.toString()
        }
      )
    } else {
      uiStore.addSnackbar(
        postDifference === 1
          ? 'my_posts_empty_results_syncing'
          : 'my_posts_empty_results_syncing_plural',
        SnackbarType.warning
      )
    }
  } else {
    uiStore.addSnackbar('manual_refresh_posts_success_still', SnackbarType.info, {
      variable1: postDifference.toString()
    })
  }
}

const updateRecentlyPublished = (postsSnapshot: Array<PostUI>) => {
  posts.value.forEach((newPost) => {
    const oldPost = postsSnapshot.find((_post) => _post.id === newPost.id)
    if (!oldPost && newPost.products.length) {
      postsStatus.value[newPost.id].hasBeenRecentlyPublished = true
    }
  })
}

onMounted(async () => await micrositeStore.fetchMicrositePosts())
</script>

<style lang="scss" scoped>
.posts {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: $spacing-size-4;
  padding: $spacing-size-4 0;

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0 $spacing-size-4;

    &__title {
      color: $neutral-700;
    }

    &__subtitle {
      color: $neutral-500;
      grid-row: 2;
    }

    &__actions {
      display: flex;
      gap: $spacing-size-1;
      grid-row: 1 / span 2;
    }

    &__action {
      $header-action: &;

      align-items: center;
      background-color: $neutral-100;
      border-radius: 50%;
      color: $neutral-700;
      display: flex;
      justify-content: center;
      padding: $spacing-size-4;

      &--disabled {
        background-color: $neutral-200;

        #{$header-action} {
          &__icon {
            &--update {
              color: $neutral-25;
            }
          }
        }
      }

      &--loading {
        cursor: wait;

        #{$header-action} {
          &__icon {
            &--update {
              animation: rotate 1.4s infinite linear;
            }
          }
        }
      }

      &__icon {
        height: $spacing-size-4;
        width: $spacing-size-4;

        &--update {
          color: $primary-500;
        }
      }
    }
  }

  &__filters {
    padding: 0 $spacing-size-4;

    &__list {
      display: flex;
      gap: $spacing-size-2;
      overflow: auto;

      &__item {
        padding: 8px 0;

        &__link {
          border: solid 1px $neutral-200;
          border-radius: 80px;
          color: $neutral-700;
          cursor: pointer;
          display: block;
          font-weight: $font-regular;
          padding: $spacing-size-2 $spacing-size-4;
          white-space: nowrap;

          &::first-letter {
            text-transform: uppercase;
          }

          &--active {
            background-color: $neutral-700;
            border: unset;
            color: #fff;
            font-weight: $font-medium;
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: $spacing-size-3;

    &--grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
      justify-content: center;

      &:deep(.post-card) {
        position: relative;
      }

      &:deep(.post-card__content) {
        align-items: flex-end;
        inset: 0;
        padding: $spacing-size-1;
        position: absolute;
      }

      &:deep(.post-card__image) {
        aspect-ratio: 1;
        height: auto !important;
        width: 100% !important;
      }

      &:deep(.post-card__title),
      &:deep(.post-card__summary__label),
      &:deep(.post-card__action) {
        display: none !important;
      }
    }

    &__item {
      cursor: pointer;
      padding: 0 $spacing-size-4;

      &--recently-published {
        background: $primary-25;
      }
    }
  }

  &__bottom-actions {
    display: flex;
    margin-top: auto;
    padding: $spacing-size-5 $spacing-size-6 $spacing-size-6;
    place-content: center center;

    &__loader {
      height: $spacing-size-8;
      width: $spacing-size-8;
    }
  }

  .post-card {
    &__action {
      align-items: center;
      background: none;
      display: flex;
      justify-content: center;

      &__link {
        cursor: pointer;
        padding: $spacing-size-4;

        &--share {
          background-color: $primary-500;
          border-radius: 50%;
          color: $primary-900;
        }
      }
    }
  }
}
</style>
