export enum EVENT_NAMES {
  SCREEN_VIEW = 'screen_view',
  USER_AUTH = 'user_auth',
  CONTENT_SYNC_START = 'content_sync_start',
  CONTENT_SYNC_END = 'content_sync_end',
  MICROSITE_CREATE = 'microsite_create',
  CONTENT_PUBLISH = 'content_publish',
  LINK_CLICK = 'link_click',
  SYNC_IG_FEED = 'sync_ig_feed',
  ONBOARDING_COMPLETE = 'onboarding_complete',
  MODAL_OPEN = 'modal_open',
  MODAL_CLOSE = 'modal_close',
  MODAL_URL_COPY = 'modal_url_copy',
  DELETE_ACCOUNT = 'delete_account',
  LINKED_ACCOUNTS_SWITCH = 'linked_accounts_switch',
  ACCOUNT_UNLINK = 'account_unlink',
  ERROR_TRY_AGAIN = 'error_try_again',
  POSTS_LOAD_MORE = 'posts_load_more',
  CONTENT_STATUS_TOGGLE = 'content_status_toggle',
  CLICK_ACCESS = 'click_access'
}

export enum SOCIAL_MEDIA {
  INSTAGRAM = 'instagram'
}

export enum USER_TYPES {
  INFLUENCER = 'influencer'
}
