import { defineStore } from 'pinia'
import { StoreNamespaces } from '@/types/store'
import type { Product } from '@/models/products.model'

export const useProductsStore = defineStore(StoreNamespaces.products, () => {
  const products = ref<Record<string, Product>>({})

  const setProduct = (product: Product): void => {
    products.value[product.id] = product
  }

  const getProduct = (id: string): Product => {
    return products.value[id]
  }

  const getProducts = (): Record<string, Product> => {
    return products.value
  }

  const $reset = () => {
    products.value = {}
  }

  return {
    setProduct,
    getProduct,
    getProducts,
    $reset
  }
})
