import { defineStore } from 'pinia'
import { StoreNamespaces } from '@/types/store'
import type { Metric, MonetizationMetrics, MonetizationTransaction } from '@/models/metric.model'
import { MetricsService } from '@/api/metrics.service'
import type { MetricsToChart } from '@/types/charts'
import { useMicrositeStore } from '@/stores/microsite'
import type { MonetizationMetricsState } from '@/types/metric-store'

export const useMetricsStore = defineStore(StoreNamespaces.metrics, () => {
  const metricsService = new MetricsService()

  const defaultMonetization = {
    dateRange: 60,
    metrics: {},
    transactions: {
      offset: 0,
      total: 0,
      items: [] as Array<MonetizationTransaction>,
      from: '',
      to: ''
    }
  }

  const metrics = ref<Metric[]>([])
  const monetizationMetrics = ref<MonetizationMetricsState>(
    defaultMonetization as MonetizationMetricsState
  )

  const fetchMetrics = async () => {
    const microSiteId = useMicrositeStore().getMicrosite().id
    if (microSiteId) {
      try {
        metrics.value = (await metricsService.getMetricsByMicrositeId(microSiteId)).reverse()
        await fetchMonetizationMetrics()
      } catch (e) {
        console.log('e', e)
      }
    }
  }

  const fetchMonetizationMetrics = async () => {
    try {
      monetizationMetrics.value.isRequesting = true
      monetizationMetrics.value.metrics = await metricsService.getMonetizationMetrics(
        monetizationMetrics.value.dateRange.toString()
      )
    } catch (e: any) {
      monetizationMetrics.value.metrics = {} as MonetizationMetrics
      if (e.status !== 404) {
        console.log('Error requesting metrics: ', e)
      }
    } finally {
      monetizationMetrics.value.isRequesting = false
    }
  }

  const fetchMonetizationTransactions = async () => {
    try {
      const data = await metricsService.getMonetizationTransactions({
        limit: 15,
        offset: monetizationMetrics.value.transactions.offset,
        range: monetizationMetrics.value.dateRange
      })
      monetizationMetrics.value.transactions.from = data.from
      monetizationMetrics.value.transactions.to = data.to
      monetizationMetrics.value.transactions.total = data.total
      monetizationMetrics.value.transactions.offset = data.offset + data.items.length
      monetizationMetrics.value.transactions.items =
        monetizationMetrics.value.transactions.items.concat(data.items)
    } catch (e) {
      console.log('e', e)
    }
  }

  const setMonetizationMetricsDateRange = (range: number) => {
    monetizationMetrics.value.dateRange = range
  }

  const clearMonetizationTransactions = () => {
    monetizationMetrics.value.transactions.items = []
    monetizationMetrics.value.transactions.from = ''
    monetizationMetrics.value.transactions.to = ''
    monetizationMetrics.value.transactions.total = 0
    monetizationMetrics.value.transactions.offset = 0
  }

  const getMetrics = computed(() => metrics.value)

  const getVisitsForChart = computed(() =>
    metrics.value.reduce<MetricsToChart>(
      (acc, metric) => {
        acc.labels.push(
          new Intl.DateTimeFormat(navigator.language, {
            weekday: 'short',
            day: '2-digit'
          }).format(new Date(metric.date))
        )
        acc.values.push(metric.totalVisits)
        return acc
      },
      { labels: [], values: [] }
    )
  )

  const getProductClicksForChart = computed(() =>
    metrics.value.reduce<MetricsToChart>(
      (acc, metric) => {
        acc.labels.push(
          new Intl.DateTimeFormat(navigator.language, {
            weekday: 'short',
            day: '2-digit'
          }).format(new Date(metric.date))
        )
        acc.values.push(metric.totalProductClicks)
        return acc
      },
      { labels: [], values: [] }
    )
  )

  const getTotalValues = computed(() => {
    const _metrics = metrics.value.reduce<
      Pick<Metric, 'totalProductClicks' | 'totalVisits' | 'uniqueVisits' | 'productCtr'>
    >(
      (acc, current) => {
        acc.totalVisits += current.totalVisits
        acc.totalProductClicks += current.totalProductClicks
        acc.productCtr += current.productCtr
        acc.uniqueVisits += current.uniqueVisits

        return acc
      },
      {
        totalProductClicks: 0,
        totalVisits: 0,
        uniqueVisits: 0,
        productCtr: 0
      }
    )

    _metrics.productCtr =
      _metrics.productCtr > 0
        ? Math.round((_metrics.productCtr / metrics.value.length) * 100) / 100
        : 0

    return _metrics
  })

  const getMetricsDates = computed(() => {
    if (!metrics.value.length) {
      return {}
    }

    const startDate = Intl.DateTimeFormat(navigator.language, {
      dateStyle: 'short'
    }).format(new Date(metrics.value[0].date))
    const endDate = Intl.DateTimeFormat(navigator.language, {
      dateStyle: 'short'
    }).format(new Date(metrics.value[metrics.value.length - 1].date))

    return {
      start: startDate,
      end: endDate
    }
  })

  const getMonetizationMetrics = () => {
    const startDate = monetizationMetrics.value.metrics.fromDate
      ? Intl.DateTimeFormat(navigator.language, { dateStyle: 'short' }).format(
          new Date(monetizationMetrics.value.metrics.fromDate)
        )
      : ''
    const endDate = monetizationMetrics.value.metrics.toDate
      ? Intl.DateTimeFormat(navigator.language, { dateStyle: 'short' }).format(
          new Date(monetizationMetrics.value.metrics.toDate)
        )
      : ''

    return Object.keys(monetizationMetrics.value.metrics).length
      ? {
          ...monetizationMetrics.value.metrics,
          fromDate: startDate,
          toDate: endDate
        }
      : ({} as MonetizationMetrics)
  }
  const getMonetizationMetricsStatus = () => monetizationMetrics.value.isRequesting
  const getMonetizationMetricsDateRange = () => monetizationMetrics.value.dateRange
  const getMonetizationTransactions = () => monetizationMetrics.value.transactions

  const $reset = () => {
    metrics.value = []
    monetizationMetrics.value = defaultMonetization as MonetizationMetricsState
  }

  return {
    monetizationMetrics,
    getMetrics,
    getVisitsForChart,
    getProductClicksForChart,
    getTotalValues,
    getMetricsDates,
    getMonetizationMetrics,
    getMonetizationMetricsStatus,
    getMonetizationMetricsDateRange,
    fetchMetrics,
    fetchMonetizationMetrics,
    setMonetizationMetricsDateRange,
    getMonetizationTransactions,
    fetchMonetizationTransactions,
    clearMonetizationTransactions,
    $reset
  }
})
