import _definePage_default_0 from '/code/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/code/src/pages/[...path].vue?definePage&vue'
import _definePage_default_3 from '/code/src/pages/access.vue?definePage&vue'
import _definePage_default_4 from '/code/src/pages/announcement.vue?definePage&vue'
import _definePage_default_5 from '/code/src/pages/error.vue?definePage&vue'
import _definePage_default_6 from '/code/src/pages/feedback.vue?definePage&vue'
import _definePage_default_7 from '/code/src/pages/onboarding/index.vue?definePage&vue'
import _definePage_default_8 from '/code/src/pages/onboarding/social-link-reception/[browser].vue?definePage&vue'
import _definePage_default_9 from '/code/src/pages/payments.vue?definePage&vue'
import _definePage_default_10 from '/code/src/pages/posts/index.vue?definePage&vue'
import _definePage_default_11 from '/code/src/pages/register.vue?definePage&vue'
import _definePage_default_12 from '/code/src/pages/settings.vue?definePage&vue'
import _definePage_default_13 from '/code/src/pages/social-accounts.vue?definePage&vue'
import _definePage_default_14 from '/code/src/pages/statistics/index.vue?definePage&vue'
import _definePage_default_15 from '/code/src/pages/statistics/monetization.vue?definePage&vue'
import _definePage_default_16 from '/code/src/pages/statistics/performance.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'admin_global_position',
    component: () => import('/code/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: 'route_name_not_found',
    component: () => import('/code/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/access',
    name: 'login_page',
    component: () => import('/code/src/pages/access.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  _mergeRouteRecord(
  {
    path: '/announcement',
    name: '/announcement',
    component: () => import('/code/src/pages/announcement.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  _mergeRouteRecord(
  {
    path: '/error',
    name: 'ob_error',
    component: () => import('/code/src/pages/error.vue'),
    /* no children */
  },
  _definePage_default_5
  ),
  _mergeRouteRecord(
  {
    path: '/feedback',
    name: '/feedback',
    component: () => import('/code/src/pages/feedback.vue'),
    /* no children */
  },
  _definePage_default_6
  ),
  {
    path: '/onboarding',
    name: '/onboarding',
    component: () => import('/code/src/pages/onboarding.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'ob_router',
        component: () => import('/code/src/pages/onboarding/index.vue'),
        /* no children */
      },
  _definePage_default_7
  ),
      {
        path: 'social-link-reception',
        /* internal name: '/onboarding/social-link-reception' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':browser',
            name: 'ob_redirect_page',
            component: () => import('/code/src/pages/onboarding/social-link-reception/[browser].vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/payments',
    name: 'my_payments',
    component: () => import('/code/src/pages/payments.vue'),
    /* no children */
  },
  _definePage_default_9
  ),
  {
    path: '/posts',
    /* internal name: '/posts' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'my_posts',
        component: () => import('/code/src/pages/posts/index.vue'),
        /* no children */
      },
  _definePage_default_10
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'ob_registration_page',
    component: () => import('/code/src/pages/register.vue'),
    /* no children */
  },
  _definePage_default_11
  ),
  _mergeRouteRecord(
  {
    path: '/settings',
    name: 'admin_settings',
    component: () => import('/code/src/pages/settings.vue'),
    /* no children */
  },
  _definePage_default_12
  ),
  _mergeRouteRecord(
  {
    path: '/social-accounts',
    name: 'social_accounts',
    component: () => import('/code/src/pages/social-accounts.vue'),
    /* no children */
  },
  _definePage_default_13
  ),
  {
    path: '/statistics',
    /* internal name: '/statistics' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'metrics_overview',
        component: () => import('/code/src/pages/statistics/index.vue'),
        /* no children */
      },
  _definePage_default_14
  ),
  _mergeRouteRecord(
      {
        path: 'monetization',
        name: 'metrics_sales',
        component: () => import('/code/src/pages/statistics/monetization.vue'),
        /* no children */
      },
  _definePage_default_15
  ),
  _mergeRouteRecord(
      {
        path: 'performance',
        name: 'metrics_performance',
        component: () => import('/code/src/pages/statistics/performance.vue'),
        /* no children */
      },
  _definePage_default_16
  )
    ],
  }
]
