import Http from '@/lib/http'
import { ENV } from '@/constants/env'
import { type UserCredentials, userCredentialsSchema } from '@/models/user-credentials.model'

export class AuthService extends Http {
  constructor() {
    super(ENV.apiBaseUrl)
  }

  async setUserToken(firebaseToken: string): Promise<UserCredentials> {
    const res = await this.post('/user-credentials', {
      firebaseToken
    })
    return userCredentialsSchema.parse(res)
  }
}
