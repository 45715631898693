import { defineStore } from 'pinia'
import { ENV } from '@/constants/env'
import { PostsService } from '@/api/post.service'
import { StoreNamespaces } from '@/types/store'
import type { PostApi } from '@/models/posts.model'
import { useMetrics } from '@/composables/useMetrics'
import { EVENT_NAMES } from '@/types/events'
import { useUiStore } from '@/stores/ui'
import type { Product } from '@/models/products.model'
import { PostStatus } from '@/types/general'

interface ContentPublishEventPayload {
  selected_products: Array<string>
  content_source: string
  content_type: string
  content_id: string
}

export interface ProductUi extends Product {}

export interface Post extends Omit<PostApi, 'products'> {
  productIds: Array<string>
}

export interface PostUI extends Omit<Post, 'productIds'> {
  products: Array<ProductUi>
}

export interface ProcessedPostData {
  selectedProductIds: Array<string>
}

export const usePostsStore = defineStore(StoreNamespaces.posts, () => {
  const postsService = new PostsService()
  const { sendEvent } = useMetrics()
  const uiStore = useUiStore()
  const productStore = useProductsStore()
  const micrositeStore = useMicrositeStore()

  const posts = ref<Record<string, Post>>({})
  const postIdsWithProducts = ref<Array<string>>([])

  const patchPost = (postId: string, selectedProductIds: Array<string> = []) => {
    posts.value[postId].status = selectedProductIds.length
      ? PostStatus.Published
      : PostStatus.Skipped

    setUserPost({
      ...posts.value[postId],
      productIds: selectedProductIds
    })

    const payloadEvent: ContentPublishEventPayload = {
      selected_products: selectedProductIds,
      content_source: 'instagram',
      content_type: posts.value[postId].type,
      content_id: postId
    }

    sendEvent(EVENT_NAMES.CONTENT_PUBLISH, payloadEvent)
  }

  const fetchPostProducts = async (postId: string) => {
    const post = await postsService.getPostData(postId)
    const selectedProductIds: Array<string> = []

    post.products.forEach((product) => {
      selectedProductIds.push(product.id)
      productStore.setProduct(product)
    })

    patchPost(postId, selectedProductIds)

    return selectedProductIds.length
  }

  const fetchProductsFromDraftPosts = async () => {
    const draftPosts = [...getDraftPosts()]
    const chunkSize = ENV.speedRun.chunkSize
    const chunks = Math.ceil(draftPosts.length / chunkSize)
    let processedPosts = 0

    uiStore.setSpeedrunStatus({ processed: 0, total: draftPosts.length })

    for (let i = 0; i < chunks; i++) {
      const chunkDraftPosts = draftPosts.splice(0, chunkSize)

      await Promise.all(
        chunkDraftPosts.map(async (post) => {
          await fetchPostProducts(post.id)

          ++processedPosts

          uiStore.setSpeedrunStatus({ processed: processedPosts })
        })
      )

      micrositeStore.microsite.isOnboardingCompleted = true
    }
  }

  const setUserPost = (post: Post) => {
    posts.value[post.id] = post
  }

  const updatePostStatus = async (postId: string) => {
    const post = posts.value[postId] || {}
    if (post.status === PostStatus.Published) {
      await postsService.unpublishPost(postId)
      patchPost(postId)
      return 0
    } else {
      return await fetchPostProducts(postId)
    }
  }
  const getMicrositePosts = (): Array<PostUI> => {
    return Object.values(posts.value)
      .map((post) => mapPostToPostUI(post))
      .sort((a, b) => b.publishedAt - a.publishedAt)
  }

  const getUserPublishedPosts = (): Array<PostUI> => {
    return getMicrositePosts().filter((post) => post.status === PostStatus.Published)
  }

  const getDraftPosts = (): Array<PostUI> => {
    return getMicrositePosts().filter((post) => post.status === PostStatus.Draft)
  }

  const getPostForOnboarding = () => {
    return Object.values(posts.value).slice(0, 3).map(mapPostToPostUI)
  }

  const mapPostToPostUI = (post: Post): PostUI => {
    const { productIds, ..._post } = post

    return {
      ..._post,
      products: productIds.map((productId) => productStore.getProduct(productId))
    }
  }

  const $reset = () => {
    posts.value = {}
    postIdsWithProducts.value = []
  }

  return {
    posts,
    postIdsWithProducts,
    setUserPost,
    getMicrositePosts,
    getUserPublishedPosts,
    fetchProductsFromDraftPosts,
    getPostForOnboarding,
    updatePostStatus,
    getDraftPosts,
    $reset
  }
})
