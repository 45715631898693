import { defineStore } from 'pinia'
import { StoreNamespaces } from '@/types/store'

export const useOnboardingStore = defineStore(StoreNamespaces.onboarding, () => {
  const isSubRouteLoading = ref<boolean>(false)

  const setSubRouteLoading = (status: boolean): void => {
    isSubRouteLoading.value = status
  }

  const getSubRouteLoading = (): boolean => {
    return isSubRouteLoading.value
  }

  const $reset = () => {
    isSubRouteLoading.value = false
  }

  return {
    isSubRouteLoading,
    setSubRouteLoading,
    getSubRouteLoading,
    $reset
  }
})
