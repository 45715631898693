import { defineStore } from 'pinia'
import { StoreNamespaces } from '@/types/store'
import { type Snackbar, SnackbarType } from '@/types/snackbar'
import { ErrorType, type GenericError } from '@/types/error'
import { useMicrositeStore } from '@/stores/microsite'

const defaultError = {
  title: 'general_error_title',
  message: 'general_error_subtitle',
  buttonLink: '/',
  buttonText: 'general_error_action',
  type: ErrorType.info,
  isCritical: false,
  delayTime: 5000
}

export interface LoaderConfig {
  animation?: string
  title?: string
  subtitle?: string
}

export interface SpeedrunStatus {
  total: number
  processed: number
}

export const useUiStore = defineStore(StoreNamespaces.ui, () => {
  const activeLoader = ref<LoaderConfig | null>(null)

  const snackbars = ref<Array<Snackbar>>([])

  const error = shallowRef<GenericError>(defaultError)

  const speedrunStatus = ref<SpeedrunStatus | null>(null)

  const showShareDialog = ref<boolean>(false)

  const showLoader = (loaderConfig: LoaderConfig): void => {
    activeLoader.value = loaderConfig
    document.querySelector('body')?.classList.add('scroll-block')
  }

  const hideLoader = (): void => {
    activeLoader.value = null
    document.querySelector('body')?.classList.remove('scroll-block')
  }

  const addSnackbar = (
    message: string,
    type: SnackbarType,
    messageVariables: Record<string, string> = {},
    canBeClosed: boolean = true
  ): number => {
    const snackbarId = Date.now()
    snackbars.value.push({
      id: snackbarId,
      type,
      message,
      messageVariables,
      canBeClosed
    })

    setTimeout(() => {
      removeSnackbar(snackbarId)
    }, 4000)

    return snackbarId
  }

  const removeSnackbar = (snackbarId: number) => {
    const index = snackbars.value.findIndex((snackbar: Snackbar) => snackbar.id === snackbarId)
    if (index > -1) {
      snackbars.value.splice(index, 1)
    }
  }

  const showRateLimitPostUpdateSnackbar = (remaningTime: number) => {
    const secondsRemaining = remaningTime / 1000

    if (secondsRemaining < 60) {
      addSnackbar('manual_refresh_posts_remaining_time_to_retry_seconds', SnackbarType.info, {
        time: Math.floor(secondsRemaining).toString()
      })
    } else if (secondsRemaining < 120) {
      addSnackbar('manual_refresh_posts_remaining_time_to_retry_minute', SnackbarType.info)
    } else {
      addSnackbar('manual_refresh_posts_remaining_time_to_retry_minutes', SnackbarType.info, {
        time: Math.floor(secondsRemaining / 60).toString()
      })
    }
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    addSnackbar('general_link_copied', SnackbarType.info)
  }

  const share = async (shareData: ShareData) => {
    if ('share' in navigator) {
      try {
        await navigator.share(shareData)
      } catch (e) {
        /* empty */
      }
    } else if (shareData.url) {
      copyToClipboard(shareData.url)
    }
  }

  const setError = (_error: Partial<GenericError>) => {
    error.value = {
      ...defaultError,
      ..._error
    }
  }

  const setSpeedrunStatus = (status: Partial<SpeedrunStatus>) => {
    speedrunStatus.value = {
      total: status?.total || speedrunStatus.value?.total || 0,
      processed: status?.processed || speedrunStatus.value?.processed || 0
    }
  }

  const getSnackbars = computed(() => () => {
    return snackbars.value
  })

  const getError = computed(() => error.value)

  const hasLoader = computed(() => activeLoader.value)

  const getSpeedrunStatus = () => speedrunStatus.value

  const resetStores = () => {
    useMetricsStore().$reset()
    useOnboardingStore().$reset()
    useProductsStore().$reset()
    useUserStore().$reset()
    usePostsStore().$reset()
    useMicrositeStore().$reset()
  }

  const setShowShareDialog = (value: boolean) => {
    showShareDialog.value = value
  }

  return {
    showShareDialog,
    activeLoader,
    getError,
    hasLoader,
    showLoader,
    hideLoader,
    addSnackbar,
    showRateLimitPostUpdateSnackbar,
    setSpeedrunStatus,
    getSnackbars,
    getSpeedrunStatus,
    removeSnackbar,
    setError,
    copyToClipboard,
    share,
    resetStores,
    setShowShareDialog
  }
})
