<template>
  <FrillEmbedWidget
    :widget-key="FRILL_WIDGET_KEYS.whatsNew.mobile"
    :sso-token="ssoToken"
    :title="t('announcement_mobile_title')"
    :subtitle="t('announcement_mobile_subtitle')"
  />
</template>

<script setup lang="ts">
import { FRILL_WIDGET_KEYS } from '@/constants/frill'

definePage({
  meta: {
    layout: 'app',
    pageTitleKey: 'general_page_title_announcement',
    name: 'admin_announcement'
  }
})

const { t } = useI18n()

const ssoToken = computed(() => useUserStore().getFeedbackToken)
</script>
