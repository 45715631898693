import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

let languageCode = 'es'
const langCodeMatch = navigator.language.match(/^[a-zA-Z]{2}/)

if (langCodeMatch?.length) {
  languageCode = langCodeMatch[0]
}
export default createI18n({
  locale: languageCode,
  messages,
  fallbackLocale: 'es'
})
