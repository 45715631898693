<template>
  <div class="monetization" style="padding: 16px">
    <header class="monetization__header">
      <div class="monetization__header__info">
        <p class="monetization__header__title text-md">
          {{ $t('monetization_title') }}
        </p>
        <p class="monetization__header__subtitle text-xs">
          {{ $t('monetization_subtitle') }}
        </p>
      </div>
      <switch-options
        :items="switchData.items"
        v-model="switchData.model"
        @change="handleChangeDateRange(switchData.model)"
      >
        <template #switch-options-prev>
          <i class="mkt-calendar" />
        </template>
      </switch-options>
    </header>
    <div class="monetization__summary">
      <ul class="monetization__summary__list">
        <li class="monetization__summary__item">
          <span class="monetization__summary__item__icon">
            <i class="mkt-shopping-bag-alt" />
          </span>
          <span class="monetization__summary__item__info">
            <p class="text-xl">
              <span class="monetization__summary__item__value">
                {{ monetizationMetrics.confirmedTotalSales || 0 }}
              </span>
              <span class="monetization__summary__item__total">
                / {{ monetizationMetrics.totalSales || 0 }}
              </span>
            </p>
            <p class="monetization__summary__item__legend text-xs">
              {{ $t('metrics_legend_confirmed_sales') }}
            </p>
          </span>
        </li>
        <li class="monetization__summary__item">
          <span class="monetization__summary__item__icon">
            <i class="mkt-shopping-bag-alt" />
          </span>
          <span class="monetization__summary__item__info">
            <p class="text-xl">
              <span
                class="monetization__summary__item__value monetization__summary__item__value--highlight"
              >
                {{ monetizationMetrics.confirmedTotalComissions || 0 }}
              </span>
              <span class="monetization__summary__item__total">
                / {{ monetizationMetrics.totalCommissions || 0 }}
              </span>
            </p>
            <p class="monetization__summary__item__legend text-xs">
              {{ $t('metrics_legend_confirmed_comissions') }}
            </p>
          </span>
        </li>
      </ul>
    </div>
    <div class="monetization__filters">
      <p class="monetization__summary__hint text-xs">
        <i class="monetization__summary__hint__icon mkt-calendar" />
        {{
          $t('metrics_date_hint', {
            variable1: monetizationMetricsDateRange,
            variable2: monetizationTransactionDates.from,
            variable3: monetizationTransactionDates.to
          })
        }}
      </p>
      <ul class="monetization__filters__list">
        <li
          v-for="(filter, index) in filtersList"
          :key="`filter-${filter.label}-${index}`"
          class="monetization__filters__list__item"
          @click="handleFiltersClick(index)"
        >
          <span
            :class="[
              'monetization__filters__list__item__link text-sm',
              {
                'monetization__filters__list__item__link--active': activeFilterIndex === index
              }
            ]"
          >
            {{ $t(filter.label) }}
          </span>
        </li>
      </ul>
    </div>
    <div>
      <template v-if="filteredTransactionsList.length">
        <Collapsable
          v-for="(item, key) in filteredTransactionsList"
          :class="[
            'monetization__product-sold',
            {
              'monetization__product-sold--pending': item.status === statusKey.pending,
              'monetization__product-sold--payed':
                item.status === statusKey.paid || item.status === statusKey.confirmed,
              'monetization__product-sold--rejected': item.status === statusKey.rejected,
              'monetization__product-sold--pending-pay': item.status === statusKey.pendingCollection
            }
          ]"
          :key="`transaction-${key}`"
        >
          <template v-slot:header>
            <div class="monetization__product-sold__header">
              <span
                :class="[
                  'monetization__product-sold__header__icon',
                  getRandomIcon(item.merchantName || 'random')
                ]"
              />
              <div class="monetization__product-sold__info">
                <div class="monetization__product-sold__info__details">
                  <p class="monetization__product-sold__info__brand text-xs">
                    {{ getFormatSaleDateHeader(item.purchaseDate) }}
                  </p>
                  <p class="monetization__product-sold__info__name text-md">
                    {{ getTransactionTitle(item) }}
                  </p>
                </div>
                <span class="monetization__product-sold__info__status" />
                <p class="monetization__product-sold__info__price text-md">
                  {{ item.amountCommission }} {{ item.currency }}
                </p>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <ul class="monetization__product-sold__details">
              <li class="monetization__product-sold__details__item">
                <span class="monetization__product-sold__details__title text-sm">{{
                  $t('transaction_status_legend')
                }}</span>
                <span
                  :class="[
                    'badge',
                    {
                      'badge--warning': item.status === statusKey.pending,
                      'badge--success':
                        item.status === statusKey.paid || item.status === statusKey.confirmed,
                      'badge--error': item.status === statusKey.rejected,
                      'badge--info': item.status === statusKey.pendingCollection
                    }
                  ]"
                >
                  <span class="monetization__product-sold__details__icon mkt-calendar" />
                  <span class="badge__label">{{ $t(statusText[item.status] || '') }}</span>
                </span>
              </li>
              <li class="monetization__product-sold__details__item">
                <span class="monetization__product-sold__details__title text-sm">{{
                  $t('transaction_purchase_date_legend')
                }}</span>
                <span class="monetization__product-sold__details__value text-sm">{{
                  getFormatSaleDate(item.purchaseDate)
                }}</span>
              </li>
              <li class="monetization__product-sold__details__item">
                <span class="monetization__product-sold__details__title text-sm">{{
                  $t('transaction_total_sale_legend')
                }}</span>
                <span class="monetization__product-sold__details__value text-sm"
                  >{{ item.totalSale }} {{ item.currency }}</span
                >
              </li>
              <li class="monetization__product-sold__details__item">
                <span class="monetization__product-sold__details__title text-sm">{{
                  $t('transaction_commision_legend')
                }}</span>
                <span class="monetization__product-sold__details__value text-sm"
                  >{{ item.amountCommission }} {{ item.currency }}</span
                >
              </li>
            </ul>
          </template>
        </Collapsable>
        <pagination-handler
          v-if="hasMoreTransactions"
          :is-requesting="isRequesting"
          @on-visibility-change="handlePagination"
        />
      </template>
      <template v-else>
        <div class="monetization__empty">
          <i class="monetization__empty__icon mkt-shopping-cart" />
          <p class="monetization__empty__title text-xl">{{ $t('transactions_empty_title') }}</p>
          <p class="monetization__empty__subtitle text-sm">
            {{ $t('transactions_empty_subtitle') }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import Collapsable from '@/components/Collapsable.vue'
import type { MonetizationTransaction } from '@/models/metric.model'

definePage({
  meta: {
    pageTitleKey: 'general_page_title_metrics_monetization',
    layout: 'app'
  },
  name: 'metrics_sales'
})

const { t } = useI18n()
const uiStore = useUiStore()
const metricsStore = useMetricsStore()

const statusKey: Record<string, string> = {
  pending: 'pending',
  paid: 'paid',
  confirmed: 'confirmed',
  rejected: 'rejected',
  pendingCollection: 'pendingcollection'
}

const statusText: Record<string, string> = {
  [statusKey.pending]: 'transaction_status_pending',
  [statusKey.paid]: 'transaction_status_paid',
  [statusKey.confirmed]: 'transaction_status_confirmed',
  [statusKey.rejected]: 'transaction_status_rejected',
  [statusKey.pendingCollection]: 'transaction_status_pending-collection'
}

const filtersList = computed(() => {
  return [
    {
      label: 'filter_all',
      value: 'all'
    },
    {
      label: 'transactions_filter_pending',
      value: statusKey.pending
    },
    {
      label: 'transactions_filter_paid',
      value: statusKey.paid
    },
    {
      label: 'transactions_filter_rejected',
      value: statusKey.rejected
    },
    {
      label: 'transactions_filter_pending-collection',
      value: statusKey.pendingCollection
    }
  ]
})
const monetizationMetrics = computed(() => metricsStore.getMonetizationMetrics())
const monetizationMetricsDateRange = computed(() => metricsStore.getMonetizationMetricsDateRange())
const monetizationTransactionInfo = computed(() => metricsStore.getMonetizationTransactions())
const monetizationTransactionDates = computed(() => {
  return {
    from: getFormatSaleDate(monetizationTransactionInfo.value.from),
    to: getFormatSaleDate(monetizationTransactionInfo.value.to)
  }
})
const transactionsList = computed(() => monetizationTransactionInfo.value.items)
const filteredTransactionsList = computed(() => {
  return filtersList.value[activeFilterIndex.value].value === 'all'
    ? transactionsList.value
    : transactionsList.value.filter(
        (transaction) => transaction.status === filtersList.value[activeFilterIndex.value].value
      )
})
const hasMoreTransactions = computed(
  () => monetizationTransactionInfo.value.items.length < monetizationTransactionInfo.value.total
)

const activeFilterIndex = ref<number>(0)
const isRequesting = ref<boolean>(false)

const switchData = {
  items: [
    {
      label: '60d',
      value: 60,
      name: 'metric'
    },
    {
      label: '30d',
      value: 30,
      name: 'metric'
    }
  ],
  model: monetizationMetricsDateRange.value
}

const getRandomIcon = (name: string) => {
  const icons = ['mkt-asterisk', 'mkt-settings-alt', 'mkt-rainbow', 'mkt-sun', 'mkt-cloudy']
  return icons[name.length % icons.length]
}

const getTransactionTitle = (transaction: MonetizationTransaction) => {
  const _status = t(statusText[transaction.status])
  return transaction.merchantName
    ? t('transaction_title', { variable1: transaction.merchantName })
    : t('transaction_title_default', { variable1: _status })
}

const getFormatSaleDateHeader = (date: string) => {
  if (date) {
    return new Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    }).format(new Date(date))
  }
}

const getFormatSaleDate = (date: string) => {
  if (date) {
    return new Intl.DateTimeFormat(navigator.language).format(new Date(date))
  }
}

const handleChangeDateRange = async (value: number) => {
  metricsStore.setMonetizationMetricsDateRange(value)
  uiStore.showLoader({
    animation: 'https://lottie.host/9ee855f4-c8d5-4833-8d65-e7338d764d28/6b5fu4UFCU.json',
    title: 'loader_creating_account_title',
    subtitle: 'loader_transactions_subtitle'
  })
  metricsStore.clearMonetizationTransactions()
  await metricsStore.fetchMonetizationMetrics()
  await metricsStore.fetchMonetizationTransactions()
  uiStore.hideLoader()
}

const handleFiltersClick = (filterIndex: number) => {
  activeFilterIndex.value = filterIndex
}

const handlePagination = async (isVisible: boolean) => {
  if (isVisible) {
    await metricsStore.fetchMonetizationTransactions()
  }
}

onMounted(async () => {
  try {
    if (!Object.keys(monetizationTransactionInfo.value.items).length) {
      uiStore.showLoader({
        animation: 'https://lottie.host/9ee855f4-c8d5-4833-8d65-e7338d764d28/6b5fu4UFCU.json',
        title: 'loader_creating_account_title',
        subtitle: 'loader_creating_account_subtitle'
      })
      await metricsStore.fetchMonetizationTransactions()
      uiStore.hideLoader()
    }
  } catch (e) {
    console.log('asnjvgdasnmjdbkjasd', e)
  } finally {
    uiStore.hideLoader()
  }
})
</script>

<style lang="scss" scoped>
$header-height: $base-unit * 3.25;

.monetization {
  padding: $spacing-size-5;

  &__header {
    display: flex;
    gap: $spacing-size-2;
    margin-bottom: $spacing-size-6;

    &__info {
      flex-grow: 1;
    }

    &__title {
      color: $neutral-700;
      font-weight: $font-semibold;
    }

    &__subtitle {
      color: $neutral-500;
    }
  }

  &__summary {
    &__list {
      display: grid;
      grid-gap: $spacing-size-2;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: $spacing-size-2;
    }

    &__item {
      border: 2px solid $neutral-100;
      border-radius: $spacing-size-1;
      display: flex;
      flex-direction: column;
      gap: $spacing-size-6;
      padding: $spacing-size-3;

      &__icon {
        background-color: $neutral-50;
        border-radius: 50%;
        color: $neutral-700;
        font-size: $font-size-2;
        height: 1.75 * $base-unit;
        outline: 1px solid $neutral-50;
        padding: $spacing-size-2;
        width: 1.75 * $base-unit;
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: $spacing-size-1;
      }

      &__value {
        color: $neutral-700;
        font-weight: $font-medium-new;

        &--highlight {
          color: $primary-500;
        }
      }

      &__legend {
        color: $neutral-400;
      }
    }

    &__hint {
      color: $neutral-400;

      &__icon {
        margin-right: $spacing-size-1;
      }
    }
  }

  &__filters {
    background-color: $white;
    padding: $spacing-size-4 0;
    position: sticky;
    top: $header-height;
    z-index: 0;

    &__list {
      display: flex;
      gap: $spacing-size-2;
      overflow: auto;

      &__item {
        padding: 8px 0;

        &__link {
          border: solid 1px $neutral-200;
          border-radius: 80px;
          color: $neutral-700;
          cursor: pointer;
          display: block;
          font-weight: $font-regular;
          padding: $spacing-size-2 $spacing-size-4;
          white-space: nowrap;

          &::first-letter {
            text-transform: uppercase;
          }

          &--active {
            background-color: $neutral-700;
            border: unset;
            color: #fff;
            font-weight: $font-medium;
          }
        }
      }
    }
  }

  &__product-sold {
    $product-sold-node: &;

    &--pending {
      #{$product-sold-node}__header {
        &__icon {
          background-color: $warning-50;
          color: $warning-500;
        }
      }

      #{$product-sold-node}__info {
        &__status {
          background-color: $warning-50;
          color: $warning-500;

          &::before {
            content: $mkt-clock;
          }
        }
      }

      #{$product-sold-node}__details {
        &__icon {
          &::before {
            content: $mkt-clock;
          }
        }
      }
    }

    &--payed {
      #{$product-sold-node}__header {
        &__icon {
          background-color: $primary-50;
          color: $primary-500;
        }
      }

      #{$product-sold-node}__info {
        &__status {
          background-color: $primary-50;
          color: $primary-500;

          &::before {
            content: $mkt-check;
          }
        }
      }

      #{$product-sold-node}__details {
        &__icon {
          &::before {
            content: $mkt-check;
          }
        }
      }
    }

    &--rejected {
      #{$product-sold-node}__header {
        &__icon {
          background-color: $error-50;
          color: $error-500;
        }
      }

      #{$product-sold-node}__info {
        &__status {
          background-color: $error-50;
          color: $error-500;

          &::before {
            content: $mkt-close-circle;
          }
        }
      }

      #{$product-sold-node}__details {
        &__icon {
          display: inline-block;

          &::before {
            content: $mkt-plus-circle;
            display: block;
            transform: rotate(45deg);
          }
        }
      }
    }

    &--pending-pay {
      #{$product-sold-node}__header {
        &__icon {
          background-color: $info-50;
          color: $info-500;
        }
      }

      #{$product-sold-node}__info {
        &__status {
          background-color: $info-50;
          color: $info-500;

          &::before {
            content: $mkt-hour-glass;
          }
        }
      }

      #{$product-sold-node}__details {
        &__icon {
          &::before {
            content: $mkt-hour-glass;
          }
        }
      }
    }

    &__header {
      align-items: center;
      display: flex;
      flex-grow: 1;
      gap: $spacing-size-2;

      &__icon {
        align-items: center;
        display: flex;
        height: $spacing-size-9;
        justify-content: center;
        width: $spacing-size-9;
      }
    }

    &__info {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;

      &__details {
        max-width: $base-unit * 10.625;
        width: 100%;
      }

      &__brand {
        color: $neutral-400;
        font-weight: $font-medium-new;
      }

      &__name {
        @include ellipsis(1, $line-height-3);

        color: $neutral-700;
        font-weight: $font-medium-new;
      }

      &__status {
        border-radius: 50%;
        display: block;
        padding: $spacing-size-1;
        text-align: center;

        &::before {
          display: block;
          font-family: $icomoon-font-family;
          font-size: $font-size-2;
          height: $spacing-size-3;
          width: $spacing-size-3;
        }
      }

      &__price {
        color: $neutral-700;
        font-weight: $font-semibold;
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      gap: $spacing-size-2;
      margin-bottom: $spacing-size-3;

      &__item {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      &__title {
        color: $neutral-400;
        font-weight: $font-medium-new;
      }

      &__value {
        color: $neutral-500;
        font-weight: $font-medium-new;
      }
    }
  }

  &__empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $spacing-size-2;
    justify-content: center;
    padding: $spacing-size-6 $spacing-size-4;
    text-align: center;

    &__icon {
      background-color: $neutral-50;
      border-radius: 50%;
      color: $neutral-700;
      display: block;
      outline: 1px solid $neutral-50;
      padding: $spacing-size-3;
    }

    &__title {
      color: $neutral-700;
    }

    &__subtitle {
      color: $neutral-500;
    }
  }
}
</style>
