<template>
  <Suspense>
    <PerformanceView />
    <template #fallback>
      <div class="metrics-skeleton">
        <div class="metrics-skeleton__card-title"></div>
        <div class="metrics-skeleton__card"></div>
        <div class="metrics-skeleton__card-title"></div>
        <div class="metrics-skeleton__card"></div>
      </div>
    </template>
  </Suspense>
</template>

<script setup lang="ts">
import PerformanceView from '@/components/metrics/PerformanceView.vue'

definePage({
  meta: {
    pageTitleKey: 'general_page_title_metrics_performance',
    layout: 'app'
  },
  name: 'metrics_performance'
})
</script>

<style lang="scss" scoped>
.metrics-skeleton {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: $spacing-size-2;
  padding: $spacing-size-7 $spacing-size-6;

  &__card-title {
    @include skeleton;

    border-radius: $spacing-size-2;
    height: $spacing-size-9;
    padding: $spacing-size-4 $spacing-size-6;
  }

  &__card {
    @include skeleton;

    border-radius: $spacing-size-2;
    height: 280px;
    width: 100%;
  }
}
</style>
