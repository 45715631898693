export enum StoreNamespaces {
  auth = 'auth',
  user = 'user',
  microsite = 'microsite',
  ui = 'ui',
  onboarding = 'onboarding',
  products = 'products',
  posts = 'posts',
  metrics = 'metrics'
}

export enum StoreStorageKeys {
  auth = 'multimarkts_user_auth'
}
