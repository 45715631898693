import { createRouter, createWebHistory } from 'vue-router/auto'
import { setupLayouts } from 'virtual:generated-layouts'
import { useAuthStore } from '@/stores/auth'
import { useMetrics } from '@/composables/useMetrics'
import { EVENT_NAMES } from '@/types/events'

const router = createRouter({
  history: createWebHistory(),
  extendRoutes: (routes) => setupLayouts(routes)
})

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = Boolean(useAuthStore().getUserToken())
  if (to.path.includes('/sign-in')) {
    next({ path: '/access', query: { ...to.query } })
  } else if (isAuthenticated && to.path.includes('/access')) {
    next({ path: '/', query: { ...to.query } })
  } else if (!isAuthenticated && to.path !== '/access' && !to.meta.public) {
    next({ path: '/access', query: { ...to.query } })
  } else {
    next()
  }
})

router.afterEach(async (to) => {
  const { sendEvent } = useMetrics()
  if (to.name !== 'ob_router') {
    sendEvent(EVENT_NAMES.SCREEN_VIEW, {
      screen_name: to.name,
      language: 'es'
    })
  }
})

export default router
