import { z } from 'zod'

export const metricSchema = z.object({
  micrositeId: z.string(),
  productCtr: z.number(),
  totalProductClicks: z.number(),
  totalVisits: z.number(),
  uniqueVisits: z.number(),
  date: z.string()
})

export const monetizationMetricSchema = z.object({
  confirmedTotalComissions: z.number(),
  confirmedTotalSales: z.number(),
  incomeConfirmedTotalSales: z.number(),
  incomeTotalSales: z.number(),
  totalCommissions: z.number(),
  totalSales: z.number(),
  fromDate: z.string().optional().default(''),
  toDate: z.string().optional().default('')
})

export const monetizationTransactionSchema = z
  .object({
    amountCommission: z.number(),
    merchantName: z.string().nullable(),
    purchaseDate: z.string(),
    status: z.string(),
    totalSale: z.number(),
    transactionId: z.string(),
    currency: z.string().optional().default('€')
  })
  .transform((transaction) => ({
    amountCommission: transaction.amountCommission,
    merchantName: transaction.merchantName,
    purchaseDate: transaction.purchaseDate,
    status: transaction.status.toLowerCase(),
    totalSale: transaction.totalSale,
    transactionId: transaction.transactionId,
    currency: transaction.currency
  }))

export const monetizationTransactionsListSchema = z.object({
  items: z.array(monetizationTransactionSchema),
  from: z.string().optional().default(''),
  to: z.string().optional().default(''),
  limit: z.number(),
  offset: z.number(),
  total: z.number()
})

export type Metric = z.infer<typeof metricSchema>
export type MonetizationMetrics = z.infer<typeof monetizationMetricSchema>
export type MonetizationTransactionsList = z.infer<typeof monetizationTransactionsListSchema>
export type MonetizationTransaction = z.infer<typeof monetizationTransactionSchema>
