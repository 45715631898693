<template>
  <RouterView />
</template>

<script setup lang="ts">
useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: () => '/favicon.svg'
    }
  ]
})

const router = useRouter()

onErrorCaptured(() => {
  router.push({
    path: '/error'
  })
})
</script>
