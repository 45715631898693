import Http from '@/lib/http'
import { ENV } from '@/constants/env'
import { profileSocialLinkSchema, type SocialLinks } from '@/models/profile.model'
import { type PostApi, postSchema } from '@/models/posts.model'
import type { SyncPostsResponse } from '@/types/api'
import { type Microsite, micrositeSchema } from '@/models/microsite.model'

export class MicrositeService extends Http {
  constructor() {
    super(ENV.apiBaseUrl)
  }

  async getUserMicrosite(): Promise<Microsite> {
    const microsite = await this.get('/microsites/me')
    return micrositeSchema.parse(microsite)
  }

  async patchMicrosite(redirectUrl: string, instagramCode: string): Promise<Microsite> {
    const microsite = await this.post(`/microsites/me/social-media-link/success`, {
      redirectUrl,
      instagramCode
    })
    return micrositeSchema.parse(microsite)
  }

  async getMicrositeSocialLinkPage(redirectUrl: string): Promise<SocialLinks> {
    const link = await this.post('/microsites/me/social-media-link', {
      redirectUrl
    })
    return profileSocialLinkSchema.parse(link)
  }

  async fetchMicrositePosts(micrositeId: string): Promise<Array<PostApi>> {
    const { items }: { items: Array<PostApi> } = await this.get(`/microsites/${micrositeId}/posts`)
    return items.map((post) => postSchema.parse(post))
  }

  async completeOnboarding() {
    try {
      await this.post(`/microsites/me/onboarding/complete`, {})
    } catch (e: any) {
      console.error(e.message)
    }
  }

  async requestUpdateNewMicrositePostsManually(micrositeId: string): Promise<SyncPostsResponse> {
    try {
      await this.post(`/microsites/${micrositeId}/posts/force-sync`, {})
      return {
        syncedPosts: 0, // TODO: Request API to unify the response with historic
        retryAfter: 0
      }
    } catch (e: any) {
      if (e.response?.status === 429) {
        const dueDate = e.response.headers.get('Retry-After')
        if (dueDate) {
          return {
            syncedPosts: -1,
            retryAfter: new Date(parseInt(dueDate)).getTime() - new Date().getTime()
          }
        } else {
          return {
            syncedPosts: -1,
            retryAfter: 30 * 60 * 1000
          }
        }
      }
      console.log('Error requesting update requestUpdateNewMicrositePostsManually')
      throw e
    }
  }

  async requestUpdatePreviousMicrositePostsManually(
    micrositeId: string
  ): Promise<SyncPostsResponse> {
    try {
      const { syncedPosts } = await this.post(
        `/microsites/${micrositeId}/posts/history-force-sync`,
        {}
      )
      return {
        syncedPosts,
        retryAfter: 0
      }
    } catch (e: any) {
      if (e.response?.status === 429) {
        const dueDate = e.response.headers.get('Retry-After')
        if (dueDate) {
          return {
            syncedPosts: -1,
            retryAfter: new Date(parseInt(dueDate)).getTime() - new Date().getTime()
          }
        } else {
          return {
            syncedPosts: -1,
            retryAfter: 60 * 1000
          }
        }
      }
      console.log('Error requesting update requestUpdateNewMicrositePostsManually')
      throw e
    }
  }

  async socialMediaUnlink(socialMedia: string) {
    await this.patch(`/microsites/me/social-media-unlink`, {
      platform: socialMedia
    })
  }
}
